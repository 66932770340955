import React, { useState } from "react";
import "./singleMember.scss";
import {
  AddCard,
  Close,
  Facebook,
  Image,
  LinkedIn,
  ListAlt,
  Twitter,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { teamType } from "../../types/globalTypes";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import { getToken } from "../../_utils/fonctions";
import MyLoadingv3 from "../loader/Loader_v3";
import ErrorBar from "../error/ErrorBar";
import team_api from "../../_service/api.caller/teamCaller";
import axios from "axios";
import FileResizer from "react-image-file-resizer";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
  data: teamType;
}

interface dataType {
  nom: string;
  postnom: string;
  prenom: string;
  email: string;
  tache: string;
  facebook: string;
  twitter: string;
  linkedin: string;
}
const SingleMember = (props: Props) => {
  const [disableMde, setdisableMde] = useState(true);
  const [data, setdata] = useState<dataType>({
    nom: props.data.nom,
    postnom: props.data.postnom,
    prenom: props.data.prenom,
    email: props.data.email,
    tache: props.data.tache,
    facebook: props.data.facebook,
    twitter: props.data.twitter,
    linkedin: props.data.linkden,
  });

  const handleChange = (e: any) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleUpdate = () => {
    if (
      data.nom.toString().length < 1 ||
      data.prenom.toString().length < 1 ||
      data.postnom.toString().length < 1 ||
      data.tache.toString().length < 1 ||
      data.email.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { nom, postnom, prenom, email, tache, facebook, twitter, linkedin } =
      data;

    team_api
      .update(
        props.data.team_id,
        { nom, postnom, prenom, email, tache, facebook, twitter, linkedin },
        token
      )
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error) => {
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  //UPLOAD FILE
  const [file, setfile] = useState<File | null>(null);
  const [base64Image, setBase64Image] = useState(null);

  const handleChangeFile = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setfile(file);
      //Resize the image file
      FileResizer.imageFileResizer(
        file,
        500, // max width
        500, // max height
        "JPEG", // output format
        100, // quality
        0, // rotation
        (uri: any) => {
          setBase64Image(uri); // Set the resized image to state
        },
        "base64", // Output type
        500, // max width
        500
      );
    }
  };

  const handleUploadImage = async () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    if (!base64Image) return;

    setState({
      isLoading: true,
      errorMessage: null,
    });

    //blow method convert the base60 into forData
    const blob = await (await fetch(base64Image)).blob();
    const formData = new FormData();
    formData.append("image", blob, "resized-image.png");

    let url = `${URL_BACKEND_APP}/team/uploadImage.php?id=${props.data.team_id}`;

    try {
      const response = await axios.post(
        url,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
      // console.log("Image saved successfully====>", response.data);
      setState({
        isLoading: false,
        errorMessage: null,
      });

      props.setSuccessUpdate(true);
      props.forceUpdateOnInsert();
      props.setopen(false);
    } catch (error: any) {
      setState({
        isLoading: false,
        errorMessage: "Vérifier votre connexion et actualiser",
      });

      // setfile(undefined);

      if (error.message === ERROR_MESSAGE.networkError) {
        return setState({
          isLoading: false,
          errorMessage: ERROR_MESSAGE.networkError,
        });
      }
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.clear();
          return navigate("/login");
        }
        if (error.response.status === 405) {
          localStorage.clear();
          return navigate("/login");
        }
      }
    }
  };

  return (
    <div className="singleMember">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <ListAlt />
            {disableMde ? <span>Détail Agent</span> : <span>Modification</span>}
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {disableMde ? (
              <div className="item">
                <span className="text-dark">Email:</span>
                <span>{data.email}</span>
              </div>
            ) : (
              <div className="item update">
                <span className="text-dark">Mis à jour des infos agent : </span>
                <span className="text-dark">{data.email}</span>
              </div>
            )}

            <div className="item">
              <span className="text-dark">Nom</span>
              <input
                name="nom"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.nom}
              />
            </div>
            <div className="item">
              <span className="text-dark">Post-nom</span>
              <input
                name="postnom"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.postnom}
              />
            </div>
            <div className="item">
              <span className="text-dark">Prénom</span>
              <input
                name="prenom"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.prenom}
              />
            </div>
            <div className="item">
              <span className="text-dark">Attribution</span>
              <input
                name="tache"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.tache}
              />
            </div>
            <div className="item">
              <div className="label">
                <Facebook className="text-dark" />
                <span className="text-dark">Facebook</span>
              </div>
              <input
                name="facebook"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.facebook}
              />
            </div>
            <div className="item">
              <div className="label">
                <Twitter className="text-dark" />
                <span className="text-dark">Twitter</span>
              </div>
              <input
                name="twitter"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.twitter}
              />
            </div>
            <div className="item">
              <div className="label">
                <LinkedIn className="text-dark" />
                <span className="text-dark">LinkedIn</span>
              </div>
              <input
                name="linkedin"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.linkedin}
              />
            </div>
          </div>

          <label className="text-dark mt-2 imageUpload" htmlFor="imageFile">
            {disableMde && (
              <>
                {file ? (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Changer l'image
                  </span>
                ) : (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Ajouter une image
                  </span>
                )}
              </>
            )}

            {!!file && (
              <span className="text-primary">
                {" "}
                <Image /> {file.name}
              </span>
            )}
          </label>

          {!!file && (
            <div className="validateBtn">
              <button onClick={() => setfile(null)}>Annuler</button>
              <button onClick={handleUploadImage}>Ajouter</button>
            </div>
          )}
          <input
            type="file"
            id="imageFile"
            // onChange={_handleImageChange}
            onChange={handleChangeFile}
            accept="image/png, image/jpeg"
            hidden
          />
          {!disableMde && (
            <div className="btns">
              <button
                onClick={() => {
                  setdisableMde(true);
                  props.setopen(false);
                }}
              >
                Annuler
              </button>
              <button onClick={handleUpdate} className="bg-dark">
                Modifier
              </button>
            </div>
          )}
          {disableMde && (
            <>
              {" "}
              <Tooltip title="Modifier">
                <div
                  className="editBtn text-dark"
                  onClick={() => setdisableMde(false)}
                >
                  <span>Edit</span>
                  <AddCard />
                </div>
              </Tooltip>
              {/* <Tooltip title="importer logo">
                <div className="addImg text-dark" onClick={() => ""}>
                  <span>Ajouer photo</span>
                  <Image />
                </div>
              </Tooltip> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleMember;
