import React, { useState } from "react";
import "./updatePass.scss";
import { Close, Lock } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { ERROR_MESSAGE } from "../../_service/_const";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import MyLoadingv3 from "../../components/loader/Loader_v3";
import ErrorBar from "../../components/error/ErrorBar";
import SuccessAlert from "../../components/alert/AlerteBar";
import admin_api from "../../_service/admin.Caller";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  data: {
    nom: string;
    postnom: string;
    prenom: string;
    admin_id: number;
    categorieAdmin_id: string;
    email: string;
  };
}
const UpdatePasswordComponent = (props: Props) => {
  const [password, setPassword] = useState<string>("");
  const [checked, setchecked] = useState<boolean>(false);

  const [successUpdate, setSuccessUpdate] = useState<Boolean>(false);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleUpdatePsw = () => {
    if (password?.toString().length < 1) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }

    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    admin_api
      .updatePassword(props.data.admin_id, { password }, token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));

        setSuccessUpdate(true);

        setTimeout(() => {
          props.setopen(false);
        }, 3000);
      })
      .catch((error) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  return (
    <div className="updatePass">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <Lock />
            <span>Mot de passe</span>
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            <div className="item ">
              <Avatar className="text-dark" />
              <span className="text-dark">{props.data.email}</span>
            </div>

            {/* <div className="item">
              <span className="text-dark">Ancien mot de passe</span>
              <input
                name="oldPass"
                onChange={handleChange}
                type="password"
                value={data.oldPass}
                placeholder="*********************"
              />
            </div> */}
            <div className="item">
              {/* <span className="text-dark">Nouveau mot de passe</span> */}
              <input
                type={checked ? "text" : "password"}
                placeholder="*************"
                autoComplete=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="remember">
                <div className="left">
                  <input
                    type="checkbox"
                    name=""
                    checked={checked}
                    onChange={(e) => setchecked(e.target.checked)}
                    className="checkbox"
                  />
                  <span>Afficher le mot de passe</span>
                </div>
              </div>
            </div>
          </div>

          <div className="btns">
            <button onClick={() => props.setopen(false)}>Annuler</button>
            <button className="bg-dark" onClick={handleUpdatePsw}>
              Valider
            </button>
          </div>
        </div>
      </div>

      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Opération effectuée avec succes"}
          theme={"success"}
        />
      )}
    </div>
  );
};

export default UpdatePasswordComponent;
