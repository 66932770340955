import React from "react";
import "./notfound.scss";
import { Link } from "react-router-dom";
import { LOGO } from "../../assets";
const NotFound = () => {
  return (
    <div className="page_notFound">
      <div className="left">
        <h2>Page non trouvée</h2>
        <h1>404</h1>
        <Link to="/">
          <span className="btn py-2 px-2 btn-primary">
            Revenir à la page d'accueil
          </span>
        </Link>
      </div>
      <div className="right">
        <img src={LOGO} alt="Lire Consulting logo  " />
      </div>
    </div>
  );
};

export default NotFound;
