export const calculPercentage: (total: number, value: number) => number = function (
    x,
    y
  ) {
    const percent = (y / x) * 100;
    if (x === 0) return 0;
    return Number(percent.toFixed(2));
  };

  export const getToken = () => {
    let token: any = null;
    const LS_user = localStorage.getItem("user") || null;
    if (LS_user) {
      token = JSON.parse(LS_user)?.token;
    }
    if (token) {
      return token;
    } else {
      return null;
    }
  };
  export const getUserInfo = () => {
    let user: any = null;
    const LS_user = localStorage.getItem("user") || null;
    if (LS_user) {
      user = JSON.parse(LS_user).user;
    }
    if (user) {
      return user;
    } else {
      return null;
    }
  };

