import React, { useState } from "react";
import "./update.scss";
import { Close, ListAlt } from "@mui/icons-material";
import { ERROR_MESSAGE } from "../../_service/_const";
import testimony_api from "../../_service/api.caller/testimonialCaller";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import ErrorBar from "../../components/error/ErrorBar";
import MyLoadingv3 from "../../components/loader/Loader_v3";
import service_api from "../../_service/api.caller/serviceDoCaller";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
  service_id: string;
  data: any;
}
function UpdateTitle(props: Props) {
  const [data, setdata] = useState(props.data.value);
  const handleChange = (e: any) => {
    setdata(e.target.value);
  };
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  let keyName: any;

  switch (props.data.label.toLowerCase()) {
    case "titre": {
      keyName = "title";
      break;
    }
    case "sous-titre": {
      keyName = "subtitle";
      break;
    }
    default:
      break;
  }

  const handleUpdate = (valueKey: string) => {
    // console.log(valueKey);
    if (data.toString().length < 1) {
      setState({
        isLoading: false,
        errorMessage: "Le champs est vide",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    service_api.serviceTitle
      .update(props.service_id, { [valueKey]: data }, token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  return (
    <div className="updateAPP">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <ListAlt />
            <span>Modification - Service</span>
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            <div className="item">
              <span className="text-dark"> {props.data.label}</span>
              <input onChange={handleChange} type="text" value={data} />
            </div>
          </div>

          <div className="btns">
            <button onClick={() => props.setopen(false)}>Annuler</button>
            <button className="bg-dark" onClick={() => handleUpdate(keyName)}>
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateTitle;
