import React, { useState } from "react";
import "./newMember.scss";
import { Add, Close, Facebook, LinkedIn, Twitter } from "@mui/icons-material";
import { requestType } from "../../types/globalTypes";
import { ERROR_MESSAGE } from "../../_service/_const";
import team_api from "../../_service/api.caller/teamCaller";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import MyLoadingv3 from "../loader/Loader_v3";
import ErrorBar from "../error/ErrorBar";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
}
interface dataType {
  nom: string;
  postnom: string;
  prenom: string;
  email: string;
  tache: string;
  facebook: string;
  twitter: string;
  linkedin: string;
}

const NewMember = (props: Props) => {
  const [data, setdata] = useState<dataType>({
    nom: "",
    postnom: "",
    prenom: "",
    email: "",
    tache: "",
    facebook: "",
    twitter: "",
    linkedin: "",
  });

  const handleChange = (e: any) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleCreate = () => {
    if (
      data.nom.toString().length < 1 ||
      data.prenom.toString().length < 1 ||
      data.postnom.toString().length < 1 ||
      data.tache.toString().length < 1 ||
      data.email.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter les champs requis",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { nom, postnom, prenom, email, tache, facebook, twitter, linkedin } =
      data;

    team_api
      .create(
        {
          nom,
          postnom,
          prenom,
          email,
          tache,
          facebook,
          twitter,
          linkedin,
          ville: "----",
          quartier: "----",
          avenue: "----",
          commune: "----",
          sexe: "----",
          code: "----",
          telephone: "----",
        },
        token
      )
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error: any) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  return (
    <div className="newMember">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <Add />
            <span>Nouvel agent</span>
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            <div className="item">
              <span>Nom*:</span>
              <input
                type="text"
                name="nom"
                value={data.nom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Post-nom*:</span>
              <input
                type="text"
                name="postnom"
                value={data.postnom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Prénom*:</span>
              <input
                type="text"
                name="prenom"
                value={data.prenom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Email*:</span>
              <input
                type="email"
                name="email"
                value={data.email}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Attribution*:</span>
              <input
                type="texte"
                name="tache"
                value={data.tache}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <div className="label">
                <Facebook className="text-dark" />
                <span>Facebook</span>
              </div>
              <input
                type="text"
                name="facebook"
                value={data.facebook}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <div className="label">
                <Twitter className="text-dark" />
                <span>Twitter</span>
              </div>
              <input
                type="text"
                name="twitter"
                value={data.twitter}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <div className="label">
                <LinkedIn className="text-dark" />
                <span>LinkedIn</span>
              </div>
              <input
                type="text"
                name="linkedin"
                value={data.linkedin}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="btns">
            <button onClick={() => props.setopen(false)}>Annuler</button>
            <button onClick={handleCreate} className="bg-dark">
              Enregister
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMember;
