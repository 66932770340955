import React from "react";
import "./request.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
// import RequestForm from "../../components/faq/NewFaq";

const Request = () => {
  return (
    <>
      <Header />
      {/* <RequestForm /> */}
      <Footer />
    </>
  );
};

export default Request;
