import React, { useState } from "react";
import "./NewActivity.scss";
import { Add, Close } from "@mui/icons-material";
import { getToken } from "../../_utils/fonctions";
import { ERROR_MESSAGE } from "../../_service/_const";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import ErrorBar from "../../components/error/ErrorBar";
import MyLoadingv3 from "../../components/loader/Loader_v3";
import actvity_api from "../../_service/api.caller/activityCaller";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
}
interface dataType {
  title: string;
  category: string;
  // content: string;
}

const NewActivity = (props: Props) => {
  const [content, setcontent] = useState("");

  const [data, setdata] = useState<dataType>({
    title: "",
    category: "",
    // content: "",
  });

  const handleChange = (e: any) => {
    // console.log(e.target.name);
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleCreate = () => {
    if (
      data.title.toString().length < 1 ||
      data.category.toString().length < 1 ||
      content.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { title, category } = data;

    actvity_api.activityItem
      .create(
        {
          title,
          category,
          content: content,
          subtitle: "---",
          author_id: "1",
        },
        token
      )
      .then((response) => {
        console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error: any) => {
        console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  return (
    <div className="NewActivity">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <Add />
            <span>Nouvelle Activité</span>
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            <div className="item">
              <span>Titre:</span>
              <input
                type="text"
                name="title"
                value={data.title}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Catégorie:</span>
              <input
                type="text"
                name="category"
                value={data?.category}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              {/* <span>Contenu:</span>
              <textarea
                rows={8}
                name="content"
                value={data.content}
                onChange={handleChange}
              /> */}
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setcontent}
                style={{
                  height: "200px", // Editor's height
                  maxHeight: "200px", // Maximum allowed height
                  overflow: "auto", // Scroll when content overflows
                }}
              />
            </div>
          </div>
          <div className="btns">
            <button onClick={() => props.setopen(false)}>Annuler</button>
            <button className="bg-dark" onClick={handleCreate}>
              Enregister
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewActivity;
