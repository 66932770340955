import React, { useReducer, useState } from "react";
import "./activity.scss";
import moment from "moment";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import MenuRoute from "../../components/menuroute/MenuRoute";
import PageTitle from "../../components/pagetitle/PageTitle";
import {
  Add,
  AppBlocking,
  Image,
  ImageNotSupportedOutlined,
  RemoveRedEye,
} from "@mui/icons-material";
import { activityTitleData } from "../../data/data";
import UpdateTitle from "./UpdateTitle";
import NewActivity from "./NewActivity";
import SingleActivity from "./SingleActivity";
import { activityType, requestType } from "../../types/globalTypes";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../_utils/fonctions";
import {
  ERROR_MESSAGE,
  STATUS_RESSOURCE,
  STATUS_RESSOURCE_ID,
} from "../../_service/_const";
import SuccessAlert from "../../components/alert/AlerteBar";
import actvity_api from "../../_service/api.caller/activityCaller";
import SearchBar from "../../components/searchBar/SearchBar";
import AttachedFileItem from "../../components/attachFile/AttachedFileItem";
import MyLoadingv3 from "../../components/loader/Loader_v3";

const Activity = () => {
  const [open, setopen] = useState<boolean>(false);
  const [openSingle, setopenSingle] = useState<boolean>(false);
  const [singleData, setsingleData] = useState<any>();
  const [data, setData] = useState<activityType[]>([]);
  const [dataTitle, setDataTitle] = useState<any[]>([]);
  const [openUpdateTitle, setopenUpdateTitle] = useState<boolean>(false);
  const [actionValue, setActionValue] = useState<string>("null");
  const [selected, setSelected] = useState<string>("aucun");
  const [searchValue, setSearchValue] = React.useState<string>("");

  const [openImage, setopenImage] = useState<boolean>(false);
  const [imageData, setImageData] = useState<any>();

  const [successUpdate, setSuccessUpdate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  const handleOpenImg = (val: any) => {
    console.log(val);
    setImageData(val);
    setopenImage(true);
  };

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const handleValidate = async (val: string) => {
    // console.log(val);
    // console.log(selected);
    // console.log(singleData);

    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    try {
      if (val === "delete") {
        await actvity_api.activityItem.delete(singleData.activity_id, token);
      } else if (val === "enable") {
        await actvity_api.activityItem.publish(singleData.activity_id, token);
      } else if (val === "disable") {
        await actvity_api.activityItem.archive(singleData.activity_id, token);
      }

      setState((prev) => ({
        ...prev,
        isLoading: false,
        succussMessage: "success",
      }));
      setSelected("aucun");
      setActionValue("null");
      setSuccessUpdate(true);
      forceUpdateOnInsert();
    } catch (error: any) {
      setState({
        isLoading: false,
        errorMessage: "Erreur de chargement, réessayer",
      });

      if (error.message === ERROR_MESSAGE.networkError) {
        return setState({
          isLoading: false,
          errorMessage: ERROR_MESSAGE.networkError,
        });
      }
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.clear();
          return navigate("/login");
        }
      }
    }
  };
  const handleSelect = (val: string) => {
    if (val === selected) setSelected("aucun");
    else setSelected(val);
  };
  const fetchAll = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    actvity_api.activityItem
      .getAll(token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  const fetchTitle = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    actvity_api.activityTitle
      .getAll(token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setDataTitle(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  //activity data for title
  activityTitleData.title[0].value = dataTitle
    ? dataTitle[0]?.title
    : "Non défini";

  activityTitleData.title[1].value = dataTitle
    ? dataTitle[0]?.subtitle
    : "Non défini";

  React.useEffect(() => {
    fetchAll();
    fetchTitle();
  }, [reducerValueOnInsert]);

  return (
    <>
      <MyLoadingv3 isLoading={state.isLoading} />

      <Header />
      <MenuRoute menuLevelOne={"Accueil"} menuLevelTwo={"Activités"} />
      <PageTitle
        title={"Gestion des Activités"}
        setopen={setopen}
        btnTitle=""
      />
      <div className="container">
        <div className="left">
          {/* <div className="search">
            <Search className="ico" />
            <input type="text" />
          </div> */}

          <div className="itemss">
            <div className="search">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              {selected !== "aucun" && (
                <div className="action">
                  <span>Action: </span>
                  <select onChange={(e) => setActionValue(e.target.value)}>
                    <option value="null">----------</option>
                    <option value="delete">Supprimer</option>
                    <option value="enable">Publier</option>
                    <option value="disable">Désactiver</option>
                  </select>
                  {actionValue !== "null" && (
                    <button
                      className="text-white bg-dark"
                      onClick={() => handleValidate(actionValue)}
                    >
                      Valider
                    </button>
                  )}
                </div>
              )}
            </div>
            <ul className="responsive-tables">
              <li className="table-header bg-dark">
                <div className="col col-1">Id</div>
                <div className="col col-3">Titre </div>
                <div className="col col-2">catégorie</div>
                <div className="col col-2">Création</div>
                <div className="col col-3">status</div>
              </li>
              {data
                .filter((item) =>
                  item.title.toLowerCase().includes(searchValue.toLowerCase())
                )
                .map((value, index: number) => {
                  // console.log(value);
                  return (
                    <li className="table-row" key={index}>
                      <div className="col col-1 number" data-label="Id:">
                        <div
                          className={
                            selected === index.toString()
                              ? "case bg-dark"
                              : "case"
                          }
                          onClick={() => {
                            handleSelect(index.toString());
                            setsingleData(value);
                          }}
                        ></div>
                        {index + 1}
                      </div>
                      <div className="col col-3" data-label="Titre:">
                        {value?.title}
                      </div>
                      <div className="col col-2" data-label="Catégorie:">
                        {value?.category}
                      </div>
                      <div className="col col-2" data-label="Création:">
                        {moment(value?.activity_created_at).format("L")}
                      </div>
                      <div className="col col-3" data-label="Status:">
                        {value.activity_status ===
                          STATUS_RESSOURCE_ID.PUBLISHED && (
                          <span className="status enable">
                            {STATUS_RESSOURCE.PUBLISHED}
                          </span>
                        )}
                        {value.activity_status ===
                          STATUS_RESSOURCE_ID.ARCHIVED && (
                          <span className="status disable">
                            {STATUS_RESSOURCE.ARCHIVED}
                          </span>
                        )}
                        {!!value.path_img ? (
                          <Image
                            onClick={() => {
                              handleOpenImg(value);
                            }}
                          />
                        ) : (
                          <ImageNotSupportedOutlined />
                        )}

                        <RemoveRedEye
                          onClick={() => {
                            setsingleData(value);
                            setopenSingle(true);
                          }}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
            <div className="item">
              <div className="left_title bg-dark">
                <AppBlocking />
                <span>Entete</span>
              </div>
              {activityTitleData.title.map((value, index: number) => {
                return (
                  <div className="left_item text-dark" key={index}>
                    <span className="label">{value.label}</span>
                    <span>{value.value}</span>
                    <div className="update">
                      <Add className="text-primary" />
                      <span
                        className="text-primary"
                        onClick={() => {
                          setsingleData(value);
                          setopenUpdateTitle(true);
                        }}
                      >
                        Modifier
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Opération effectuée avec succes"}
          theme={"success"}
        />
      )}

      {openUpdateTitle && (
        <UpdateTitle
          setopen={setopenUpdateTitle}
          data={singleData}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
          activity_id={dataTitle[0].activity_id}
        />
      )}

      {open && (
        <NewActivity
          setopen={setopen}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}
      {openSingle && (
        <SingleActivity
          setopen={setopenSingle}
          data={singleData}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}

      {openImage && (
        <AttachedFileItem
          setOpen={setopenImage}
          data={imageData.path_img}
          title1={`Image - Activité`}
          title2={`${imageData.title}`}
        />
      )}
      <Footer />
    </>
  );
};

export default Activity;
