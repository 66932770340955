import React, { useState } from "react";
import "./update.scss";
import { Close, FileDownload, ListAlt } from "@mui/icons-material";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import axios from "axios";
import { getToken } from "../../_utils/fonctions";
import MyLoadingv3 from "../../components/loader/Loader_v3";
import ErrorBar from "../../components/error/ErrorBar";
import Resizer from "react-image-file-resizer";
import FileResizer from "react-image-file-resizer";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
  whatwedo_id: string;
  data: any;
}
function UpdateImg(props: Props) {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  // -----------------------------------------
  //RESIZE FILE
  //UPLOAD FILE
  const [file, setfile] = useState<File | null>(null);
  const [base64Image, setBase64Image] = useState(null);

  const handleChangeFile = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setfile(file);
      //Resize the image file
      FileResizer.imageFileResizer(
        file,
        800, // max width
        800, // max height
        "JPEG", // output format
        100, // quality
        0, // rotation
        (uri: any) => {
          setBase64Image(uri); // Set the resized image to state
        },
        "base64" // Output type
      );
    }
  };

  const handleUploadImage = async () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    if (!base64Image) return;

    setState({
      isLoading: true,
      errorMessage: null,
    });

    //blow method convert the base60 into forData
    const blob = await (await fetch(base64Image)).blob();
    const formData = new FormData();
    formData.append("image", blob, "resized-image.png");

    let url = `${URL_BACKEND_APP}/whatwedo/uploadImage.php?id=${props.whatwedo_id}`;

    try {
      const response = await axios.post(
        url,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
      // console.log("Image saved successfully====>", response.data);
      setState({
        isLoading: false,
        errorMessage: null,
      });

      props.setSuccessUpdate(true);
      props.forceUpdateOnInsert();
      props.setopen(false);
    } catch (error: any) {
      setState({
        isLoading: false,
        errorMessage: "Vérifier votre connexion et actualiser",
      });

      // setfile(undefined);

      if (error.message === ERROR_MESSAGE.networkError) {
        return setState({
          isLoading: false,
          errorMessage: ERROR_MESSAGE.networkError,
        });
      }
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.clear();
          return navigate("/login");
        }
        if (error.response.status === 405) {
          localStorage.clear();
          return navigate("/login");
        }
      }
    }
  };

  return (
    <div className="updateAPP">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <ListAlt />
            <span>Modification - Ce que nous faisons</span>
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            <div className="item">
              <span className="text-dark">{"Téléverser une image"}</span>
              <div className="inputContainer">
                <label className="icon" htmlFor="imageFile">
                  <FileDownload />
                </label>
                <input
                  type="file"
                  id="imageFile"
                  onChange={handleChangeFile}
                  // onChange={handleChangeFile}
                  accept="image/png, image/jpeg"
                />
              </div>
            </div>
            {base64Image !== null && <img src={base64Image} alt="" />}
          </div>

          <div className="btns">
            <button onClick={() => props.setopen(false)}>Annuler</button>
            {/* <button className="bg-dark" onClick={handleUploadImage}> */}
            <button className="bg-dark" onClick={handleUploadImage}>
              Téléverser
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateImg;
