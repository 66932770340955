import React, { useState } from "react";
import "./SingleActivity.scss";
import { AddCard, Close, Image, ListAlt } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { activityType } from "../../types/globalTypes";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import { getToken } from "../../_utils/fonctions";
import { requestType } from "../../types/globalTypes";
import { useNavigate } from "react-router-dom";
import actvity_api from "../../_service/api.caller/activityCaller";
import ErrorBar from "../../components/error/ErrorBar";
import MyLoadingv3 from "../../components/loader/Loader_v3";
import axios from "axios";
import FileResizer from "react-image-file-resizer";
import ReactQuill from "react-quill";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
  data: activityType;
}
const SingleActivity = (props: Props) => {
  const [disableMde, setdisableMde] = useState(true);
  const [data, setdata] = useState({
    title: props.data.title,
    category: props.data.category,
    // content: props.data.content,
  });
  const handleChange = (e: any) => {
    // console.log(e.target.name);
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();
  const [value, setvalue] = useState(props.data.content);

  const handleUpdate = () => {
    if (
      data.title?.toString().length < 1 ||
      data.category?.toString().length < 1 ||
      value?.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { title, category } = data;

    actvity_api.activityItem
      .update(
        props.data.activity_id,
        { title, content: value, category },
        token
      )
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error) => {
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  //UPLOAD FILE
  const [file, setfile] = useState<File | null>(null);
  const [base64Image, setBase64Image] = useState(null);

  const handleChangeFile = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setfile(file);
      //Resize the image file
      FileResizer.imageFileResizer(
        file,
        500, // max width
        350, // max height
        "JPEG", // output format
        100, // quality
        0, // rotation
        (uri: any) => {
          setBase64Image(uri); // Set the resized image to state
        },
        "base64" // Output type
      );
    }
  };

  const handleUploadImage = async () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    if (!base64Image) return;

    setState({
      isLoading: true,
      errorMessage: null,
    });

    //blow method convert the base60 into forData
    const blob = await (await fetch(base64Image)).blob();
    const formData = new FormData();
    formData.append("image", blob, "resized-image.png");

    let url = `${URL_BACKEND_APP}/activity_item/uploadImage.php?id=${props.data.activity_id}`;

    try {
      await axios.post(
        url,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
      // console.log("Image saved successfully====>", response.data);
      setState({
        isLoading: false,
        errorMessage: null,
      });

      props.setSuccessUpdate(true);
      props.forceUpdateOnInsert();
      props.setopen(false);
    } catch (error: any) {
      setState({
        isLoading: false,
        errorMessage: "Vérifier votre connexion et actualiser",
      });

      // setfile(undefined);

      if (error.message === ERROR_MESSAGE.networkError) {
        return setState({
          isLoading: false,
          errorMessage: ERROR_MESSAGE.networkError,
        });
      }
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.clear();
          return navigate("/login");
        }
        if (error.response.status === 405) {
          localStorage.clear();
          return navigate("/login");
        }
      }
    }
  };
  return (
    <div className="SingleActivity">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <ListAlt />
            {disableMde ? (
              <span>Détail Activité</span>
            ) : (
              <span>Modification</span>
            )}
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            <div className="item">
              <span className="text-dark">Titre:</span>
              <input
                name="title"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.title}
              />
            </div>
            <div className="item">
              <span className="text-dark">Catégorie:</span>
              <input
                name="category"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data?.category}
              />
            </div>
            <div className="item">
              <span className="text-dark">Contenu:</span>
              {disableMde ? (
                <div
                  style={{
                    height: "200px", // Editor's height
                    maxHeight: "200px", // Maximum allowed height
                    overflow: "auto", // Scroll when content overflows
                    border: "1px solid #c1c1c1",
                    padding: "10px",
                  }}
                  dangerouslySetInnerHTML={{ __html: props.data.content }}
                />
              ) : (
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setvalue}
                  style={{
                    height: "200px", // Editor's height
                    maxHeight: "200px", // Maximum allowed height
                    overflow: "auto", // Scroll when content overflows
                  }}
                />
              )}

              {/* <textarea
                rows={8}
                name="content"
                onChange={handleChange}
                disabled={disableMde}
                value={data.content}
              /> */}
            </div>

            {/* <div className="last_item">
              {disableMde && (
                <div className="right_image">
                  <span className="text-dark">Autres images:</span>
                  <div className="img"></div>
                  <div className="img"></div>
                  <div className="img"></div>
                </div>
              )}
            </div> */}
          </div>

          <label className="text-dark mt-2 imageUpload" htmlFor="imageFile">
            {disableMde && (
              <>
                {file ? (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Changer l'image
                  </span>
                ) : (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Ajouter une image
                  </span>
                )}
              </>
            )}

            {!!file && (
              <span className="text-primary">
                {" "}
                <Image /> {file.name}
              </span>
            )}
          </label>

          {!!file && (
            <div className="validateBtn">
              <button onClick={() => setfile(null)}>Annuler</button>
              <button onClick={handleUploadImage}>Ajouter</button>
            </div>
          )}
          <input
            type="file"
            id="imageFile"
            // onChange={_handleImageChange}
            onChange={handleChangeFile}
            accept="image/png, image/jpeg"
            hidden
          />
          {!disableMde && (
            <div className="btns">
              <button onClick={() => setdisableMde(true)}>Annuler</button>
              <button className="bg-dark" onClick={handleUpdate}>
                Enregister
              </button>
            </div>
          )}
          {disableMde && (
            <>
              {" "}
              <Tooltip title="Modifier">
                <div
                  className="editBtn text-dark"
                  onClick={() => setdisableMde(false)}
                >
                  <span>Edit</span>
                  <AddCard />
                </div>
              </Tooltip>
              {/* <Tooltip title="importer logo">
                <div className="addImg text-dark" onClick={() => ""}>
                  <span>Ajouter image</span>
                  <Image />
                </div>
              </Tooltip> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleActivity;
