import React, { useReducer, useState } from "react";
import "./application.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import MenuRoute from "../../components/menuroute/MenuRoute";
import {
  Add,
  AppBlocking,
  EmailOutlined,
  LocationCityOutlined,
  SocialDistance,
} from "@mui/icons-material";
import { appData } from "../../data/data";
import Update from "./Update";
import PageTitleWithoutBtn from "../../components/pagetitle/PageTitleWithoutBtn";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGE } from "../../_service/_const";
import lireInfo_api from "../../_service/api.caller/lireCaller";
import { lireInfoType, requestType } from "../../types/globalTypes";
import { getToken } from "../../_utils/fonctions";
import SuccessAlert from "../../components/alert/AlerteBar";
import MyLoadingv3 from "../../components/loader/Loader_v3";
// import SearchBar from "../../components/searchBar/SearchBar";

const Application = () => {
  const [openUpdate, setopenUpdate] = useState<boolean>(false);
  const [singleData, setSingleData] = useState<any>();
  const [successUpdate, setSuccessUpdate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );
  // const [searchValue, setSearchValue] = React.useState<string>("");

  const [data, setData] = React.useState<lireInfoType[]>([
    {
      adresse: "",
      email: "",
      facebook: "",
      instagram: "",
      twitter: "",
      linkden: "",
      lire_created_at: "",
      lire_designation: "",
      lire_published_at: "",
      lire_updated_at: "",
      slogan: "",
      telephone: "",
    },
  ]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const fetchAll = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    lireInfo_api
      .getAll(token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  //for app identity
  appData.identity[0].value = data[0].lire_designation;
  appData.identity[1].value = "1.0.0";
  //for app media social
  appData.mediaSocial[0].value = data[0].facebook;
  appData.mediaSocial[1].value = data[0].twitter;
  appData.mediaSocial[2].value = data[0].linkden;
  appData.mediaSocial[3].value = data[0].instagram;
  //for app contact
  appData.contact[0].value = data[0].email;
  appData.contact[1].value = data[0].telephone;
  //for app location
  appData.location[0].value = data[0].adresse;

  React.useEffect(() => {
    fetchAll();
  }, [reducerValueOnInsert]);
  return (
    <>
      <MyLoadingv3 isLoading={state.isLoading} />

      <Header />
      <MenuRoute
        menuLevelOne={"Accueil"}
        menuLevelTwo={"Configuration"}
        menuLevelThree={"application"}
      />
      <PageTitleWithoutBtn title={"Configuration de l'application"} />

      <div className="container">
        <div className="left">
          {/* <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          /> */}
          <div className="items">
            <div className="item">
              <div className="left_title bg-dark">
                <AppBlocking />
                <span>Indentité</span>
              </div>
              {appData.identity.map((value, index) => {
                // console.log(value);
                return (
                  <div className="left_item text-dark" key={index}>
                    <span>{value.label}</span>
                    <span>{value.value}</span>
                    <div className="update">
                      <Add
                        className={
                          value.label.toLowerCase() === "version"
                            ? "text_disabled"
                            : "text-primary"
                        }
                      />
                      <span
                        className={
                          value.label.toLowerCase() === "version"
                            ? "text_disabled"
                            : "text-primary"
                        }
                        onClick={
                          value.label.toLowerCase() === "version"
                            ? () => {}
                            : () => {
                                setSingleData(value);
                                setopenUpdate(true);
                              }
                        }
                      >
                        Modifier
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="item">
              <div className="left_title bg-dark">
                <SocialDistance />
                <span>Média sociaux</span>
              </div>
              {appData.mediaSocial.map((value, index) => {
                return (
                  <div className="left_item text-dark" key={index}>
                    <div className="media">
                      {value.icon}
                      <span className="text-dark">{value.label}</span>
                    </div>
                    <span>{value.value}</span>
                    <div className="update">
                      <Add className="text-primary" />
                      <span
                        className="text-primary"
                        onClick={() => {
                          setSingleData(value);
                          setopenUpdate(true);
                        }}
                      >
                        Modifier
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="item">
              <div className="left_title bg-dark">
                <EmailOutlined />
                <span>Contact</span>
              </div>
              {appData.contact.map((value, index) => {
                return (
                  <div className="left_item text-dark" key={index}>
                    <span>{value.label}</span>
                    <span>{value.value}</span>
                    <div className="update">
                      <Add className="text-primary" />
                      <span
                        className="text-primary"
                        onClick={() => {
                          setSingleData(value);
                          setopenUpdate(true);
                        }}
                      >
                        Modifier
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="item">
              <div className="left_title bg-dark">
                <LocationCityOutlined />
                <span>Location</span>
              </div>

              {appData.location.map((value, index) => {
                return (
                  <div className="left_item text-dark" key={index}>
                    <span>{value.label}</span>
                    <span>{value.value}</span>
                    <div className="update">
                      <Add className="text-primary" />
                      <span
                        className="text-primary"
                        onClick={() => {
                          setSingleData(value);
                          setopenUpdate(true);
                        }}
                      >
                        Modifier
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {state.errorMessage && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Une erreur s'est produit, réessayer plus tard"}
          theme={"fail"}
        />
      )}
      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Modification effectuée avec succes"}
          theme={"success"}
        />
      )}
      {openUpdate && (
        <Update
          setopen={setopenUpdate}
          data={singleData}
          id={data[0]?.lire_id}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}
      <Footer />
    </>
  );
};

export default Application;
