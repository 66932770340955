import React, { useState } from "react";
import "./newAdmin.scss";
import { Add, Close } from "@mui/icons-material";
import { ERROR_MESSAGE } from "../../_service/_const";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import admin_api from "../../_service/admin.Caller";
import { getToken } from "../../_utils/fonctions";
import ErrorBar from "../error/ErrorBar";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
}
interface AdminType {
  nom: string;
  postnom: string;
  prenom: string;
  email: string;
  password: string;
  type: string;
}

const NewAdmin = (props: Props) => {
  const [data, setdata] = useState<AdminType>({
    nom: "",
    postnom: "",
    prenom: "",
    email: "",
    password: "",
    type: "1",
  });

  const handleChange = (e: any) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleCreate = () => {
    if (
      data.nom.toString().length < 1 ||
      data.prenom.toString().length < 1 ||
      data.postnom.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { nom, postnom, prenom, email, password } = data;

    admin_api
      .create(
        {
          nom,
          postnom,
          prenom,
          email,
          password,
          sexe: "----",
          categorieAdmin_id: data.type,
          ville: "----",
          commune: "----",
          quartier: "----",
          avenue: "----",
          telephone: "----",
        },
        token
      )
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error) => {
        // console.log(error);

        if (error.response) {
          if (error.response.status === 401) {
            return setState({
              isLoading: false,
              errorMessage: error.response.data.message,
            });
          }
        }

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }

        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }

        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });
      });
  };

  return (
    <div className="newAdmin">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <Add />
            <span>Nouveau admin</span>
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <div className="item">
              <span>Nom:</span>
              <input
                type="text"
                name="nom"
                value={data.nom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Post-nom:</span>
              <input
                type="text"
                name="postnom"
                value={data.postnom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Prénom:</span>
              <input
                type="text"
                name="prenom"
                value={data.prenom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Email:</span>
              <input
                type="text"
                name="email"
                value={data.email}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Mot de passe:</span>
              <input
                type="password"
                name="password"
                value={data.password}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span className="text-dark">Type:</span>
              <select name="type" onChange={handleChange} value={data.type}>
                <option value="2">Editeur</option>
                <option value="1">Super admin</option>
              </select>
            </div>
          </div>
          <div className="btns">
            <button onClick={() => props.setopen(false)}>Annuler</button>
            <button className="bg-dark" onClick={handleCreate}>
              Enregister
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAdmin;
