import axios from "axios";
import { URL_BACKEND_APP } from "../_const";

const API_CALLER = axios.create({
  baseURL: URL_BACKEND_APP + "/activity",
});
const API_CALLER_ITEM = axios.create({
  baseURL: URL_BACKEND_APP + "/activity_item",
});

const actvity_api = {
  activityTitle: {
    create: (data, token) => {
      return API_CALLER.post("/create.php", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getOne: (id, token) => {
      return API_CALLER.get(`/readById.php?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },

    getAll: (token) => {
      return API_CALLER.get("/read.php", {
        headers: { Authorization: `Bearer ${token}` },
      });
    },

    update: (id, data, token) => {
      return API_CALLER.put("/update.php?id=" + id, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    publish: (id, token) => {
      return API_CALLER.get(`/publish.php?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    archive: (id, token) => {
      return API_CALLER.get(`/archive.php?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
  },

  activityItem: {
    create: (data, token) => {
      return API_CALLER_ITEM.post("/create.php", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getOne: (id, token) => {
      return API_CALLER_ITEM.get(`/readById.php?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },

    getAll: (token) => {
      return API_CALLER_ITEM.get("/read.php", {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    delete: (id, token) => {
      return API_CALLER_ITEM.delete(`/delete.php?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    update: (id, data, token) => {
      return API_CALLER_ITEM.put("/update.php?id=" + id, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    publish: (id, token) => {
      return API_CALLER_ITEM.get(`/publish.php?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    archive: (id, token) => {
      return API_CALLER_ITEM.get(`/archive.php?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
  },
};

export default actvity_api;
