import React, { useState } from "react";
import "./singleAdmin.scss";
import { AddCard, Close, ListAlt, Lock, LockClock } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import admin_api from "../../_service/api.caller/adminCaller";
import { ERROR_MESSAGE } from "../../_service/_const";
import MyLoadingv3 from "../loader/Loader_v3";
import ErrorBar from "../error/ErrorBar";
import UpdatePasswordComponent from "./UpdatePassword";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
  data: {
    nom: string;
    postnom: string;
    prenom: string;
    admin_id: number;
    categorieAdmin_id: string;
    email: string;
  };
}
const SingleAdmin = (props: Props) => {
  const [disableMde, setdisableMde] = useState(true);
  const [openPass, setOpenPass] = useState(false);

  const [data, setdata] = useState({
    nom: props.data.nom,
    postnom: props.data.postnom,
    prenom: props.data.prenom,
    type: props.data.categorieAdmin_id,
  });

  const handleChange = (e: any) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleUpdate = () => {
    if (
      data.nom.toString().length < 1 ||
      data.prenom.toString().length < 1 ||
      data.postnom.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { nom, postnom, prenom } = data;

    admin_api
      .update(
        props.data.admin_id,
        { nom, postnom, prenom, categorieAdmin_id: data.type },
        token
      )
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  return (
    <div className="singleAdmin">
      <MyLoadingv3 isLoading={state.isLoading} />
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <ListAlt />
            {disableMde ? (
              <span>Détail Administrateur</span>
            ) : (
              <span>Modification</span>
            )}
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            {disableMde ? (
              <div className="item">
                <span className="text-dark">Email:</span>
                <span>{props.data.email.toLowerCase()}</span>
              </div>
            ) : (
              <div className="item update">
                <span className="text-dark">Mis à jour des infos admin : </span>
                <span className="text-dark">
                  {props.data.email.toLowerCase()}
                </span>
              </div>
            )}

            <div className="item">
              <span className="text-dark">Nom:</span>
              <input
                name="nom"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.nom}
              />
            </div>
            <div className="item">
              <span className="text-dark">Post-nom:</span>
              <input
                name="postnom"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.postnom}
              />
            </div>
            <div className="item">
              <span className="text-dark">Prénom:</span>
              <input
                name="prenom"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.prenom}
              />
            </div>
            <div className="item">
              <span className="text-dark">Type:</span>
              <select
                name="type"
                onChange={handleChange}
                disabled={disableMde}
                value={data.type}
              >
                <option value="1">Super admin</option>
                <option value="2">Editeur</option>
              </select>
            </div>
          </div>
          {!disableMde && (
            <div className="btns">
              <button onClick={() => setdisableMde(true)}>Annuler</button>
              <button className="bg-dark" onClick={handleUpdate}>
                Modifier
              </button>
            </div>
          )}
          {disableMde && (
            <>
              <Tooltip title="Modifier">
                <div
                  className="editBtn text-dark"
                  onClick={() => setdisableMde(false)}
                >
                  <span>Edit</span>
                  <AddCard />
                </div>
              </Tooltip>
              {/* <Tooltip title="Changer le mot de passe">
                <div
                  className="addImg text-dark"
                  onClick={() => {
                    setOpenPass(true);
                  }}
                >
                  <span>Pass</span>
                  <Lock />
                </div>
              </Tooltip> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleAdmin;
