import React, { useState } from "react";
import "./singlePartener.scss";
import { Add, Close } from "@mui/icons-material";
import partener_api from "../../_service/api.caller/partenerCaller";
import { getToken } from "../../_utils/fonctions";
import { ERROR_MESSAGE } from "../../_service/_const";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import ErrorBar from "../error/ErrorBar";
import MyLoadingv3 from "../loader/Loader_v3";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
}

interface dataType {
  nom: string;
  abbreviation: string;
  site: string;
}

const NewPartener = (props: Props) => {
  const [data, setdata] = useState<dataType>({
    nom: "",
    site: "",
    abbreviation: "",
  });

  const handleChange = (e: any) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleCreate = () => {
    if (
      data.nom.toString().length < 1 ||
      data.site.toString().length < 1 ||
      data.abbreviation.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { nom, site, abbreviation } = data;

    partener_api
      .create(
        {
          code: abbreviation,
          designation: nom,
          url_site: site,
        },
        token
      )
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error: any) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };
  return (
    <div className="newPartener">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <Add />
            <span>Nouveau partenaire</span>
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            <div className="item">
              <span>Nom du partenaire:</span>
              <input
                type="text"
                value={data.nom}
                name="nom"
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Abbréviation:</span>
              <input
                type="text"
                value={data.abbreviation}
                name="abbreviation"
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Site internet:</span>
              <input
                type="text"
                value={data.site}
                name="site"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="btns">
            <button onClick={() => props.setopen(false)}>Annuler</button>
            <button onClick={handleCreate} className="bg-dark">
              Enregister
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPartener;
