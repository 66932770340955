import React, { useContext, useState } from "react";
import "./login.scss";
// import Logo from "../../assets/logo_nagritech.png";
// import admin_api from "../../_service/admin.Caller";
import { ERROR_MESSAGE } from "../../_service/_const";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
// import { requestType } from "../../types/GlobalType";
// import MyLoadingv2 from "../../components/loader/Loader_v2";
// import { jwtDecode } from "jwt-decode";
import { LOGO } from "../../assets";
import { requestType } from "../../types/globalTypes";
import admin_api from "../../_service/admin.Caller";
import MyLoadingv3 from "../../components/loader/Loader_v3";

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [checked, setchecked] = useState<boolean>(false);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();
  const { dispatch } = useContext<any>(AuthContext);

  const handleSubmit = async () => {
    setState({
      isLoading: true,
      errorMessage: null,
    });

    if (email.toString().length < 1 || password.toString().length < 1)
      return setState({
        isLoading: false,
        errorMessage:
          "L'adresse mail et le mot de passe ne doivent pas être vide",
      });

    await admin_api
      .login({ email, password })
      .then((response) => {
        // console.log(response);

        if (response.data.status === 201) {
          // const token = response.data.token;
          // const decoded = jwtDecode(token);
          // console.log(decoded);

          dispatch({ type: "LOGIN", payload: response.data });
          //This settimeout fx helps us to wait the user data to be stored
          //in LocalStorage and recupere directly to be served for displaying user and in used for protectedSection
          setTimeout(() => {
            navigate("/");
            window.location.reload();
          }, 500);
        }
        setState({
          isLoading: false,
          errorMessage: null,
        });
      })
      .catch((error: any) => {
        // console.log(error);
        if (error.response) {
          if (error.response.status === 406)
            return setState({
              isLoading: false,
              errorMessage: "Adresse email ou mot de passe incorrect",
            });
          if (error.response.status === 401)
            return setState({
              isLoading: false,
              errorMessage: "Adresse email ou mot de passe incorrect",
            });
        }
        if (error.message === ERROR_MESSAGE.networkError) {
          return setTimeout(() => {
            setState({
              isLoading: false,
              errorMessage: "Vérifier votre connexion et réessayer",
            });
          }, 2000);
        } else {
          return setState({
            isLoading: false,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
        }
      });
  };

  return (
    <>
      <div className="login">
        <div className="right">
          <div className="topText">
            <img src={LOGO} alt="Logo" />
            <h3 className="text-primary w-100 text-center">Login</h3>
            {/* <span>Bienvenu! Veuillez entrer vos identifiants</span> */}
          </div>
          <form>
            <div className="item">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                placeholder="Entrer votre email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="item">
              <label htmlFor="password">Mot de passe</label>
              <input
                type={checked ? "text" : "password"}
                placeholder="*************"
                autoComplete=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="remember">
              <div className="left">
                <input
                  type="checkbox"
                  name=""
                  checked={checked}
                  onChange={(e) => setchecked(e.target.checked)}
                  className="checkbox"
                />
                <span>Afficher le mot de passe</span>
              </div>
            </div>

            <input
              type="button"
              value="Connexion"
              className="connexion"
              onClick={handleSubmit}
            />
          </form>
          {state.errorMessage && (
            <div className="errorMsg">{state.errorMessage}</div>
          )}
        </div>
      </div>
      <MyLoadingv3 isLoading={state.isLoading} />
    </>
  );
};

export default Login;
