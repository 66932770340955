import React, { useState } from "react";
import "./singlePartener.scss";
import { AddCard, Close, Image, ListAlt } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import { getToken } from "../../_utils/fonctions";
import partener_api from "../../_service/api.caller/partenerCaller";
import { requestType } from "../../types/globalTypes";
import { useNavigate } from "react-router-dom";
import ErrorBar from "../error/ErrorBar";
import MyLoadingv3 from "../loader/Loader_v3";
import axios from "axios";
import FileResizer from "react-image-file-resizer";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
  data: {
    code: string;
    partner_designation: string;
    partner_id: string;
    partner_published_at: string;
    partner_created_at: string;
    partner_status: number;
    partner_updated_at: string;
    url_site: string;
  };
}
const SinglePartener = (props: Props) => {
  const [disableMde, setdisableMde] = useState(true);
  const [data, setdata] = useState({
    id: props.data.partner_id,
    nom: props.data.partner_designation,
    abbreviation: props.data.code,
    site: props.data.url_site,
    date: props.data.partner_created_at,
  });
  const handleChange = (e: any) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleUpdate = () => {
    if (
      data.nom?.toString().length < 1 ||
      data.abbreviation?.toString().length < 1 ||
      data.site?.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { nom, abbreviation, site } = data;

    partener_api
      .update(
        props.data.partner_id,
        { designation: nom, code: abbreviation, url_site: site },
        token
      )
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error) => {
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  //UPLOAD FILE
  const [file, setfile] = useState<File | null>(null);
  const [base64Image, setBase64Image] = useState(null);

  const handleChangeFile = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setfile(file);
      //Resize the image file
      FileResizer.imageFileResizer(
        file,
        300, // max width
        100, // max height
        "JPEG", // output format
        100, // quality
        0, // rotation
        (uri: any) => {
          setBase64Image(uri); // Set the resized image to state
        },
        "base64" // Output type
      );
    }
  };

  const handleUploadImage = async () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    if (!base64Image) return;

    setState({
      isLoading: true,
      errorMessage: null,
    });

    //blow method convert the base60 into forData
    const blob = await (await fetch(base64Image)).blob();
    const formData = new FormData();
    formData.append("image", blob, "resized-image.png");

    let url = `${URL_BACKEND_APP}/partner/uploadLogo.php?id=${props.data.partner_id}`;

    try {
      await axios.post(
        url,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
      // console.log("Image saved successfully====>", response.data);
      setState({
        isLoading: false,
        errorMessage: null,
      });

      props.setSuccessUpdate(true);
      props.forceUpdateOnInsert();
      props.setopen(false);
    } catch (error: any) {
      setState({
        isLoading: false,
        errorMessage: "Vérifier votre connexion et actualiser",
      });

      // setfile(undefined);

      if (error.message === ERROR_MESSAGE.networkError) {
        return setState({
          isLoading: false,
          errorMessage: ERROR_MESSAGE.networkError,
        });
      }
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.clear();
          return navigate("/login");
        }
        if (error.response.status === 405) {
          localStorage.clear();
          return navigate("/login");
        }
      }
    }
  };
  return (
    <div className="newPartener">
      <div className="dialog">
        <div className="top">
          <div className="title">
            <ListAlt />
            {disableMde ? (
              <span>Détail partenaire</span>
            ) : (
              <span>Modification</span>
            )}
          </div>
          <Close className="ico" onClick={() => props.setopen(false)} />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            {disableMde ? (
              <div className="item">
                <span className="text-dark">Date de création:</span>
                <span>{data.date}</span>
              </div>
            ) : (
              <div className="item">
                <span className="text-dark">
                  Mis à jour des infos partenaires
                </span>
              </div>
            )}

            <div className="item">
              <span className="text-dark">Nom partenaire:</span>
              <input
                name="nom"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.nom}
              />
            </div>
            <div className="item">
              <span className="text-dark">Abbréviation:</span>
              <input
                name="abbreviation"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.abbreviation}
              />
            </div>
            <div className="item">
              <span className="text-dark">Site internet:</span>
              <input
                name="site"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.site}
              />
            </div>
          </div>

          <label className="text-dark mt-2 imageUpload" htmlFor="imageFile">
            {disableMde && (
              <>
                {file ? (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Changer le logo
                  </span>
                ) : (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Ajouter un logo
                  </span>
                )}
              </>
            )}

            {!!file && (
              <span className="text-primary">
                {" "}
                <Image /> {file.name}
              </span>
            )}
          </label>

          {!!file && (
            <div className="validateBtn">
              <button onClick={() => setfile(null)}>Annuler</button>
              <button onClick={handleUploadImage}>Ajouter</button>
            </div>
          )}
          <input
            type="file"
            id="imageFile"
            // onChange={_handleImageChange}
            onChange={handleChangeFile}
            accept="image/png, image/jpeg"
            hidden
          />
          {!disableMde && (
            <div className="btns">
              <button onClick={() => setdisableMde(true)}>Annuler</button>
              <button onClick={handleUpdate}>Modifier</button>
            </div>
          )}
          {disableMde && (
            <>
              {" "}
              <Tooltip title="Modifier">
                <div
                  className="editBtn text-dark"
                  onClick={() => setdisableMde(false)}
                >
                  <span>Edit</span>
                  <AddCard />
                </div>
              </Tooltip>
              {/* <Tooltip title="importer logo">
                <div className="addImg text-dark" onClick={() => ""}>
                  <span>logo</span>
                  <Image />
                </div>
              </Tooltip> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SinglePartener;
