import React, { useState } from "react";
import "./update.scss";
import { Close, FileDownload, ListAlt } from "@mui/icons-material";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import axios from "axios";
import { getToken } from "../../_utils/fonctions";
import ErrorBar from "../../components/error/ErrorBar";
import MyLoadingv3 from "../../components/loader/Loader_v3";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
  about_id: string;
  data: any;
}
function UpdateImg(props: Props) {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  // -----------------------------------------
  //UPLOAD FILE
  const [file, setfile] = useState<File | null>(null);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setfile(files[0]);
    }
  };

  const handleUploadImage = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    let formData = new FormData();
    if (file) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      formData.append("image", file);
      formData.append("name", "MyNotifImage");

      let url = `${URL_BACKEND_APP}/about/uploadImage.php?id=${props.about_id}`;

      axios({
        url,
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        data: formData,
      }).then(
        (res) => {
          // console.log("=====>", res);

          setState({
            isLoading: false,
            errorMessage: null,
          });

          setfile(null);

          props.setSuccessUpdate(true);
          props.forceUpdateOnInsert();
          props.setopen(false);
        },
        (error) => {
          // console.log("ERRRRRRRRRRRRRRROR", error);
          setState({
            isLoading: false,
            errorMessage: "Vérifier votre connexion et actualiser",
          });

          // setfile(undefined);

          if (error.message === ERROR_MESSAGE.networkError) {
            return setState({
              isLoading: false,
              errorMessage: ERROR_MESSAGE.networkError,
            });
          }
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
            if (error.response.status === 405) {
              localStorage.clear();
              return navigate("/login");
            }
          }
        }
      );
    }
  };
  return (
    <div className="updateAPP">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <ListAlt />
            <span>Modification - Ce que nous faisons</span>
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            <div className="item">
              <span className="text-dark">{"Téléverser une image"}</span>
              <div className="inputContainer">
                <label className="icon" htmlFor="imageFile">
                  <FileDownload />
                </label>
                <input
                  type="file"
                  id="imageFile"
                  // onChange={_handleImageChange}
                  onChange={handleChangeFile}
                  accept="image/png, image/jpeg"
                />
              </div>
            </div>
          </div>

          <div className="btns">
            <button onClick={() => props.setopen(false)}>Annuler</button>
            <button className="bg-dark" onClick={handleUploadImage}>
              {/* <button className="bg-dark" onClick={_handleUpload}> */}
              Téléverser
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateImg;
