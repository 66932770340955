import React from "react";
import "./attachedFile.scss";
import { ArrowBackIosNewSharp, ArrowRight, Close } from "@mui/icons-material";
import { URL_BACKEND_APP } from "../../_service/_const";
import { IconButton } from "@mui/material";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: string;
  title1?: string;
  title2?: string;
};
const AttachedFileItem = (props: Props) => {
  let array = props.data.split("/");
  let array_2: any = [];
  for (let i = 1; i < array.length; i++) {
    array_2.push(array[i]);
  }

  let url: string = array_2.join("/");
  let img_url = `${URL_BACKEND_APP}/${url}`;

  return (
    <div className="dialog_attached_File">
      <div className="header_t bg-dark">
        <div className="header_title">
          <IconButton aria-label="return" size="large">
            <ArrowBackIosNewSharp
              className="ico"
              onClick={() => props.setOpen(false)}
            />
          </IconButton>
          <span className="title_h">{props.title1}</span>
          <ArrowRight className="ico" />
          <span className="name">{props.title2}</span>
        </div>
        <Close className="ico" onClick={() => props.setOpen(false)} />
      </div>

      <div className="dialog">
        <img
          src={img_url}
          alt="preuve_paiement_img"
          width="100%"
          height="100%"
          style={{ objectFit: "contain" }}
        />
      </div>
    </div>
  );
};

export default AttachedFileItem;
