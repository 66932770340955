import React, { useState } from "react";
import "./singleFaq.scss";
import { AddCard, Close, ListAlt } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { ERROR_MESSAGE } from "../../_service/_const";
import { getToken } from "../../_utils/fonctions";
import faq_api from "../../_service/api.caller/faqCaller";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import MyLoadingv3 from "../loader/Loader_v3";
import ErrorBar from "../error/ErrorBar";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
  data: {
    faq_id: string;
    question: string;
    answer: string;
    faq_created_at: string;
  };
}

interface dataType {
  question: string;
  answer: string;
}
const SingleFaq = (props: Props) => {
  const [disableMde, setdisableMde] = useState(true);
  const [data, setdata] = useState<dataType>({
    question: props.data.question,
    answer: props.data.answer,
  });

  const handleChange = (e: any) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleUpdate = () => {
    if (
      data.question.toString().length < 1 ||
      data.answer.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { question, answer } = data;

    faq_api
      .update(props.data.faq_id, { question, answer }, token)
      .then((response) => {
        console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error) => {
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  return (
    <div className="newfaw">
      <div className="dialog">
        <div className="top">
          <div className="title">
            <ListAlt />
            {disableMde ? <span>Détail </span> : <span>Modification</span>}
          </div>
          <Close className="ico" onClick={() => props.setopen(false)} />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            {disableMde ? (
              <div className="item">
                <span className="text-dark">Date de création:</span>
                <span>{props.data.faq_created_at}</span>
              </div>
            ) : (
              <div className="item">
                <span className="text-dark">Mis à jour de la question</span>
              </div>
            )}

            <div className="item">
              <span className="text-dark">Question:</span>
              <textarea
                name="question"
                onChange={handleChange}
                disabled={disableMde}
                rows={4}
                value={data.question}
              />
            </div>
            <div className="item">
              <span className="text-dark">Réponse:</span>
              <textarea
                name="answer"
                onChange={handleChange}
                disabled={disableMde}
                rows={5}
                value={data.answer}
              />
            </div>
          </div>
          {!disableMde && (
            <div className="btns">
              <button onClick={() => setdisableMde(true)}>Annuler</button>
              <button onClick={handleUpdate}>Enregister</button>
            </div>
          )}
          {disableMde && (
            <Tooltip title="Modifier">
              <div
                className="editBtn text-dark"
                onClick={() => setdisableMde(false)}
              >
                edit
                <AddCard className="text-dark" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleFaq;
