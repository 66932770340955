import React, { useReducer, useState } from "react";
import "./user.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import MenuRoute from "../../components/menuroute/MenuRoute";
import PageTitle from "../../components/pagetitle/PageTitle";
import SingleAdmin from "../../components/user/SingleAdmin";
import NewAdmin from "../../components/user/NewAdmin";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import { adminType, requestType } from "../../types/globalTypes";
import {
  ERROR_MESSAGE,
  STATUS_ADMIN,
  STATUS_ADMIN_ID,
} from "../../_service/_const";
import admin_api from "../../_service/admin.Caller";
import SuccessAlert from "../../components/alert/AlerteBar";
import SearchBar from "../../components/searchBar/SearchBar";
import { Lock, RemoveRedEye } from "@mui/icons-material";
import UpdatePasswordComponent from "./UpdatePassword";
import { Tooltip } from "@mui/material";
import MyLoadingv3 from "../../components/loader/Loader_v3";

const Admin = () => {
  const [open, setopen] = useState<boolean>(false);
  const [openSingle, setopenSingle] = useState<boolean>(false);
  const [singleData, setSingleData] = useState<any>();
  const [openPass, setOpenPass] = useState(false);
  const [data, setData] = useState<adminType[]>([]);
  const [actionValue, setActionValue] = useState<string>("null");
  const [selected, setSelected] = useState<string>("aucun");

  const [searchValue, setSearchValue] = React.useState<string>("");

  const [successUpdate, setSuccessUpdate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const handleSelect = (val: string) => {
    if (val === selected) setSelected("aucun");
    else setSelected(val);
  };

  const handleValidate = async (val: string) => {
    // console.log(val);
    // console.log(selected);
    // console.log(singleData);

    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    try {
      if (val === "delete") {
        await admin_api.delete(singleData.admin_id, token);
      } else if (val === "enable") {
        await admin_api.enable(singleData.admin_id, token);
      } else if (val === "disable") {
        await admin_api.disable(singleData.admin_id, token);
      }

      setState((prev) => ({
        ...prev,
        isLoading: false,
        succussMessage: "success",
      }));
      setSelected("aucun");
      setActionValue("null");
      setSuccessUpdate(true);
      forceUpdateOnInsert();
    } catch (error: any) {
      setState({
        isLoading: false,
        errorMessage: "erreur de chargement, réessayer",
      });

      if (error.message === ERROR_MESSAGE.networkError) {
        return setState({
          isLoading: false,
          errorMessage: ERROR_MESSAGE.networkError,
        });
      }
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.clear();
          return navigate("/login");
        }
      }
    }
  };

  const fetchAll = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    admin_api
      .getAll(token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  React.useEffect(() => {
    fetchAll();
  }, [reducerValueOnInsert]);

  return (
    <>
      <Header />
      <MenuRoute menuLevelOne={"Accueil"} menuLevelTwo={"Admin"} />
      <PageTitle
        title={"Gestion des administrateurs"}
        btnTitle=""
        setopen={setopen}
      />
      <div className="container">
        <div className="left">
          <div className="search">
            <SearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            {selected !== "aucun" && (
              <div className="action">
                <span>Action: </span>
                <select onChange={(e) => setActionValue(e.target.value)}>
                  <option value="null">----------</option>
                  <option value="delete">Supprimer</option>
                  <option value="enable">Activer</option>
                  <option value="disable">Désactiver</option>
                </select>
                {actionValue !== "null" && (
                  <button
                    className="text-white bg-dark"
                    onClick={() => handleValidate(actionValue)}
                  >
                    Valider
                  </button>
                )}
              </div>
            )}
          </div>

          <ul className="responsive-table">
            <li className="table-header bg-dark">
              <div className="col col-1">Id</div>
              <div className="col col-3">Email</div>
              <div className="col col-3">Noms </div>
              <div className="col col-4">Status</div>
            </li>
            {data
              .filter(
                (item) =>
                  item.nom.toLowerCase().includes(searchValue.toLowerCase()) ||
                  item.postnom
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  item.email
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  item.prenom.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((value: adminType, index: number) => {
                return (
                  <li className="table-row" key={index}>
                    <div className="col col-1 number" data-label="Id:">
                      <div
                        className={
                          selected === index.toString()
                            ? "case bg-dark"
                            : "case"
                        }
                        onClick={() => {
                          handleSelect(index.toString());
                          setSingleData(value);
                        }}
                      ></div>
                      {index + 1}
                    </div>
                    <div className="col col-3" data-label="Email:">
                      {value.email}
                    </div>
                    <div className="col col-4" data-label="Noms:">
                      {value.nom} &nbsp;
                      {value.postnom} &nbsp;
                    </div>

                    <div className="col col-3 linkBtn" data-label="Status:">
                      {value.admin_status === STATUS_ADMIN_ID.ENABLED && (
                        <span className="status enable">
                          {STATUS_ADMIN.ENABLED}
                        </span>
                      )}
                      {value.admin_status === STATUS_ADMIN_ID.DISABLED && (
                        <span className="status disable">
                          {STATUS_ADMIN.DISABLED}
                        </span>
                      )}
                      <Tooltip title="Changer le mot de passe">
                        <Lock
                          className="text-dark"
                          onClick={() => {
                            setOpenPass(true);
                            setSingleData(value);
                          }}
                        />
                      </Tooltip>

                      <RemoveRedEye
                        onClick={() => {
                          setSingleData(value);
                          setopenSingle(true);
                        }}
                      />
                      {/* <h6
                        className="text-dark"
                        onClick={() => {
                          setSingleData(value);
                          setopenSingle(true);
                        }}
                      >
                        Détail
                      </h6> */}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        {/* <div className="right">
          <div className="recent_title text-dark">
            <span>FILTRE</span>
          </div>
          <div className="recent_items">
            <span className="recenth1 text-dark">Tous</span>

            <div className="itemContainer">
              <div className="item">
                <Edit className="ico text-primary" />
                <div className="right_item">
                  <span>Ajout Activité</span>
                  <span>activité</span>
                </div>
              </div>
              <div className="item">
                <Edit className="ico text-primary" />
                <div className="right_item">
                  <span>Ajout Activité</span>
                  <span>activité</span>
                </div>
              </div>
              <div className="item">
                <Add className="ico text-primary" />
                <div className="right_item">
                  <span>Ajout Activité</span>
                  <span>activité</span>
                </div>
              </div>
              <div className="item">
                <Edit className="ico text-primary" />
                <div className="right_item">
                  <span>Ajout Activité</span>
                  <span>activité</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {openPass && (
        <UpdatePasswordComponent setopen={setOpenPass} data={singleData} />
      )}

      {open && (
        <NewAdmin
          setopen={setopen}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}

      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Opération effectuée avec succes"}
          theme={"success"}
        />
      )}
      {openSingle && (
        <SingleAdmin
          setopen={setopenSingle}
          data={singleData}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}

      <MyLoadingv3 isLoading={state.isLoading} />

      <Footer />
    </>
  );
};

export default Admin;
