import { Dialog, DialogContent } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

type Props = {
  isLoading: boolean;
};
const styles = {
  root: {
    backgroundColor: "transparent",
  },
};
const MyLoadingv3 = ({ isLoading }: Props) => {
  return (
    <>
      {isLoading && (
        <Dialog
          open={isLoading}
          PaperProps={{
            style: { backgroundColor: "transparent", boxShadow: "none" },
          }}
        >
          <div
            className="loader"
            style={{
              width: "100vw",
              height: "100vh",
              position: "fixed",
              top: "0px",
              left: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#10172577",
            }}
          >
            <RotatingLines
              visible={true}
              width="40"
              strokeColor="#fff"
              strokeWidth="3"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
            />
            {/* <span style={{ marginTop: 10 }}>Chargement...</span> */}
          </div>
        </Dialog>
      )}
    </>
  );
};

export default MyLoadingv3;
