import { Search } from "@mui/icons-material";
import React, { useState } from "react";

type Props = {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
};
const SearchBar = (props: Props) => {
  return (
    <div className="seach-bar">
      <Search className="ico" />
      <input
        type="text"
        placeholder="Rechercher..."
        value={props.searchValue}
        onChange={(e) => props.setSearchValue(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
