import React, { useState } from "react";
import "./newTestimonial.scss";
import { Add, Close } from "@mui/icons-material";
import { ERROR_MESSAGE } from "../../_service/_const";
import testimony_api from "../../_service/api.caller/testimonialCaller";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import { requestType, testimonyType } from "../../types/globalTypes";
import ErrorBar from "../error/ErrorBar";
import MyLoadingv3 from "../loader/Loader_v3";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
}
interface dataType {
  nom: "";
  detail: "";
  profession: "";
}
const NewTestimonial = (props: Props) => {
  const [data, setdata] = useState<dataType>({
    nom: "",
    detail: "",
    profession: "",
  });

  const handleChange = (e: any) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleCreate = () => {
    if (
      data.nom.toString().length < 1 ||
      data.detail.toString().length < 1 ||
      data.profession.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { nom, detail, profession } = data;

    testimony_api
      .create(
        {
          nom,
          detail,
          profession,
        },
        token
      )
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error: any) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  return (
    <div className="newTestimonial">
      <MyLoadingv3 isLoading={state.isLoading} />
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <Add />
            <span>Nouveau témoignage</span>
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <div className="item">
              <span>Nom du témoin:</span>
              <input
                type="text"
                name="nom"
                value={data.nom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Profession:</span>
              <input
                type="text"
                name="profession"
                value={data.profession}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <span>Témoignage:</span>
              <textarea
                rows={3}
                name="detail"
                value={data.detail}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="btns">
            <button onClick={() => props.setopen(false)}>Annuler</button>
            <button className="bg-dark" onClick={handleCreate}>
              Enregister
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTestimonial;
