import React, { useReducer, useState } from "react";
import "./partener.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import MenuRoute from "../../components/menuroute/MenuRoute";
import PageTitle from "../../components/pagetitle/PageTitle";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../_utils/fonctions";
import {
  ERROR_MESSAGE,
  STATUS_RESSOURCE,
  STATUS_RESSOURCE_ID,
} from "../../_service/_const";
import SearchBar from "../../components/searchBar/SearchBar";
import SuccessAlert from "../../components/alert/AlerteBar";
import { partenerType, requestType } from "../../types/globalTypes";
import NewPartener from "../../components/partener/NewPartener";
import SinglePartener from "../../components/partener/SinglePartener";
import {
  Image,
  ImageNotSupportedOutlined,
  RemoveRedEye,
} from "@mui/icons-material";
import partener_api from "../../_service/api.caller/partenerCaller";
import AttachedFileItem from "../../components/attachFile/AttachedFileItem";
import MyLoadingv3 from "../../components/loader/Loader_v3";

const Partener = () => {
  const [open, setopen] = useState<boolean>(false);
  const [openSingle, setopenSingle] = useState<boolean>(false);
  const [singleData, setsingleData] = useState<any>();
  const [data, setData] = useState<partenerType[]>([]);
  const [actionValue, setActionValue] = useState<string>("null");
  const [selected, setSelected] = useState<string>("aucun");
  const [searchValue, setSearchValue] = React.useState<string>("");

  const [openImage, setopenImage] = useState<boolean>(false);
  const [imageData, setImageData] = useState<any>();

  const [successUpdate, setSuccessUpdate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  const handleOpenImg = (val: any) => {
    // console.log(val);
    setImageData(val);
    setopenImage(true);
  };

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const handleValidate = async (val: string) => {
    // console.log(val);
    // console.log(selected);
    // console.log(singleData);

    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    try {
      if (val === "delete") {
        await partener_api.delete(singleData.partner_id, token);
      } else if (val === "enable") {
        await partener_api.publish(singleData.partner_id, token);
      } else if (val === "disable") {
        await partener_api.archive(singleData.partner_id, token);
      }

      setState((prev) => ({
        ...prev,
        isLoading: false,
        succussMessage: "success",
      }));
      setSelected("aucun");
      setActionValue("null");
      setSuccessUpdate(true);
      forceUpdateOnInsert();
    } catch (error: any) {
      setState({
        isLoading: false,
        errorMessage: "Erreur de chargement, réessayer",
      });

      if (error.message === ERROR_MESSAGE.networkError) {
        return setState({
          isLoading: false,
          errorMessage: ERROR_MESSAGE.networkError,
        });
      }
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.clear();
          return navigate("/login");
        }
      }
    }
  };
  const handleSelect = (val: string) => {
    if (val === selected) setSelected("aucun");
    else setSelected(val);
  };
  const fetchAll = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    partener_api
      .getAll(token)
      .then((response) => {
        console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };
  React.useEffect(() => {
    fetchAll();
  }, [reducerValueOnInsert]);
  return (
    <>
      <MyLoadingv3 isLoading={state.isLoading} />

      <Header />
      <MenuRoute
        menuLevelOne={"Accueil"}
        menuLevelTwo={"Pages & Sections"}
        menuLevelThree={"Partenaire"}
      />

      <PageTitle
        title={"Gestion des partenaires"}
        setopen={setopen}
        btnTitle=""
      />
      <div className="container">
        <div className="left">
          <div className="search">
            <SearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            {selected !== "aucun" && (
              <div className="action">
                <span>Action: </span>
                <select onChange={(e) => setActionValue(e.target.value)}>
                  <option value="null">----------</option>
                  <option value="delete">Supprimer</option>
                  <option value="enable">Publier</option>
                  <option value="disable">Archiver</option>
                </select>
                {actionValue !== "null" && (
                  <button
                    className="text-white bg-dark"
                    onClick={() => handleValidate(actionValue)}
                  >
                    Valider
                  </button>
                )}
              </div>
            )}
          </div>

          <ul className="responsive-table">
            <li className="table-header bg-dark">
              <div className="col col-1">Id</div>
              <div className="col col-3">Nom </div>
              <div className="col col-4">Site Web</div>
              <div className="col col-3">Status</div>
            </li>
            {data
              .filter(
                (item) =>
                  item.partner_designation
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  item.url_site
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
              )
              .map((value: partenerType, index: number) => {
                return (
                  <li className="table-row" key={index}>
                    <div className="col col-1 number" data-label="Id:">
                      <div
                        className={
                          selected === index.toString()
                            ? "case bg-dark"
                            : "case"
                        }
                        onClick={() => {
                          handleSelect(index.toString());
                          setsingleData(value);
                        }}
                      ></div>
                      {index + 1}
                    </div>
                    <div className="col col-3" data-label="Noms:">
                      {value.partner_designation}
                    </div>
                    <div className="col col-4" data-label="Site:">
                      {value.url_site}
                    </div>
                    <div className="col col-3" data-label="Status:">
                      {value.partner_status ===
                        STATUS_RESSOURCE_ID.PUBLISHED && (
                        <span className="status enable">
                          {STATUS_RESSOURCE.PUBLISHED}
                        </span>
                      )}
                      {value.partner_status ===
                        STATUS_RESSOURCE_ID.ARCHIVED && (
                        <span className="status disable">
                          {STATUS_RESSOURCE.ARCHIVED}
                        </span>
                      )}
                      {!!value.path_logo ? (
                        <Image
                          onClick={() => {
                            handleOpenImg(value);
                          }}
                        />
                      ) : (
                        <ImageNotSupportedOutlined />
                      )}

                      <RemoveRedEye
                        onClick={() => {
                          setsingleData(value);
                          setopenSingle(true);
                        }}
                      />
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        {/* <div className="right"<div className="recent_title text-dark"<span>FILTRE</span</div<div className="recent_items"<span className="recenth1 text-dark">Tous</span><div className="itemContainer"<div className="item"<Edit className="ico text-primary" /<div className="right_item"<span>Ajout Activité</span<span>activité</span</div</div<div className="item"<Edit className="ico text-primary" /<div className="right_item"<span>Ajout Activité</span<span>activité</span</div</div<div className="item"<Add className="ico text-primary" /<div className="right_item"<span>Ajout Activité</span<span>activité</span</div</div<div className="item"<Edit className="ico text-primary" /<div className="right_item"<span>Ajout Activité</span<span>activité</span</div</div</div</div</div> */}
      </div>
      {open && (
        <NewPartener
          setopen={setopen}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}

      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Opération effectuée avec succes"}
          theme={"success"}
        />
      )}

      {openSingle && (
        <SinglePartener
          setopen={setopenSingle}
          data={singleData}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}
      {openImage && (
        <AttachedFileItem
          setOpen={setopenImage}
          data={imageData.path_logo}
          title1={`Partenaire`}
          title2={`${imageData.partner_designation}`}
        />
      )}
      <Footer />
    </>
  );
};

export default Partener;
