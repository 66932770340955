import "./header.scss";
import { Logout, Person } from "@mui/icons-material";
import { LOGO } from "../../assets";
import { useContext, useState } from "react";
import UpdatePasswordComponent from "./UpdatePassword";
import { ERROR_MESSAGE } from "../../_service/_const";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";

import admin_api from "../../_service/admin.Caller";
import { AuthContext } from "../../context/AuthContext";
import { Tooltip } from "@mui/material";

const Header = () => {
  const [open, setopen] = useState(false);
  const { dispatch } = useContext<any>(AuthContext);

  const navigate = useNavigate();

  const [state, setState] = useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const handleDisconnect = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    admin_api
      .logout(token)
      .then((response) => {
        dispatch({ type: "LOGIN" });
        localStorage.clear();
        return navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        localStorage.clear();
        navigate("/login");
        setState({
          isLoading: false,
          errorMessage: "Erreur inconnue s'est produite, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  return (
    <div className="header">
      <div className="top_header bg_header">
        <div className="header_title">
          <img src={LOGO} alt="logo" />
          <span className="app_title text-primary">
            LireConsulting Administration
          </span>
          <span className="app_title_sm text-primary">
            LireConsulting Admin
          </span>
        </div>
        <div className="icons sm_Screen">
          <Person className="ico" />
          Admin &nbsp;&nbsp;
          <Logout />
          <span onClick={handleDisconnect}>Logout</span>
        </div>
        <Tooltip title="Logout">
          <Logout
            className="text-primary ico_logout_sm"
            onClick={handleDisconnect}
          />
        </Tooltip>
      </div>
      <div className="bottom_header bg_header">
        <div className="app_subtitle">
          <span>BIENVENU,</span>
          <span>Admin .</span>
          <span>
            <a
              href="https://www.lireconsulting.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              VOIR SITE
            </a>
          </span>
          <span className="passChange" onClick={() => setopen(true)}>
            CHANGER MOT DE PASSE
          </span>
        </div>
      </div>

      {open && <UpdatePasswordComponent setopen={setopen} />}
    </div>
  );
};

export default Header;
