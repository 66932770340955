import React, { useState } from "react";
import "./SingleService.scss";
import { AddCard, Close, Image, ListAlt } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { serviceType } from "../../types/globalTypes";
import { ERROR_MESSAGE } from "../../_service/_const";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/globalTypes";
import MyLoadingv3 from "../../components/loader/Loader_v3";
import ErrorBar from "../../components/error/ErrorBar";
import service_api from "../../_service/api.caller/serviceDoCaller";

interface Props {
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
  serviceItem_id: string;
  data: serviceType;
}
const SingleService = (props: Props) => {
  // console.log(props.data);
  const [disableMde, setdisableMde] = useState(true);
  const [data, setdata] = useState({
    title: props.data.title,
    content: props.data.content,
  });
  const handleChange = (e: any) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleUpdate = () => {
    if (
      data.title?.toString().length < 1 ||
      data.content?.toString().length < 1
    ) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez compléter tous les champs",
      });
      return setTimeout(() => {
        setState({
          isLoading: false,
          errorMessage: null,
        });
      }, 3000);
    }
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    let { title, content } = data;

    service_api.serviceItem
      .update(props.data.serviceItem_id, { title, content }, token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        props.setSuccessUpdate(true);
        props.forceUpdateOnInsert();
        props.setopen(false);
      })
      .catch((error) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  return (
    <div className="SingleService">
      <div className="dialog">
        <div className="top">
          <div className="title text-dark">
            <ListAlt />
            {disableMde ? (
              <span>Détail service</span>
            ) : (
              <span>Modification</span>
            )}
          </div>
          <Close
            className="ico text-danger"
            onClick={() => props.setopen(false)}
          />
        </div>
        <div className="bottom">
          <div className="items">
            {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <MyLoadingv3 isLoading={state.isLoading} />
            <div className="item">
              <span className="text-dark">Titre:</span>
              <input
                name="title"
                onChange={handleChange}
                disabled={disableMde}
                type="text"
                value={data.title}
              />
            </div>

            <div className="item">
              <span className="text-dark">Description(MAX 100 caratères)</span>
              <textarea
                rows={8}
                name="content"
                onChange={handleChange}
                disabled={disableMde}
                value={data.content}
                maxLength={100}
              />
            </div>
          </div>
          {!disableMde && (
            <div className="btns">
              <button onClick={() => setdisableMde(true)}>Annuler</button>
              <button className="bg-dark" onClick={handleUpdate}>
                Enregister
              </button>
            </div>
          )}
          {disableMde && (
            <>
              {" "}
              <Tooltip title="Modifier">
                <div
                  className="editBtn text-dark"
                  onClick={() => setdisableMde(false)}
                >
                  <span>Edit</span>
                  <AddCard />
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleService;
