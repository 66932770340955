import React, { useReducer, useState } from "react";
import "./service.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import MenuRoute from "../../components/menuroute/MenuRoute";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Add, AppBlocking, RemoveRedEye } from "@mui/icons-material";
import { activityTitleData } from "../../data/data";
import UpdateTitle from "./UpdateTitle";
import NewService from "./NewService";
import { requestType, serviceType } from "../../types/globalTypes";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../_utils/fonctions";
import {
  ERROR_MESSAGE,
  STATUS_RESSOURCE,
  STATUS_RESSOURCE_ID,
} from "../../_service/_const";
import SuccessAlert from "../../components/alert/AlerteBar";
import service_api from "../../_service/api.caller/serviceDoCaller";
import SearchBar from "../../components/searchBar/SearchBar";
import SingleService from "./SingleService";
import MyLoadingv3 from "../../components/loader/Loader_v3";

const Service = () => {
  const [open, setopen] = useState<boolean>(false);
  const [openSingle, setopenSingle] = useState<boolean>(false);
  const [data, setData] = useState<serviceType[]>([]);
  const [dataTitle, setDataTitle] = useState<any[]>([]);
  const [openUpdateTitle, setopenUpdateTitle] = useState<boolean>(false);
  const [singleData, setSingleData] = useState<any>();
  const [actionValue, setActionValue] = useState<string>("null");
  const [selected, setSelected] = useState<string>("aucun");
  const [searchValue, setSearchValue] = React.useState<string>("");

  const [successUpdate, setSuccessUpdate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleValidate = async (val: string) => {
    // console.log(val);
    // console.log(selected);
    // console.log(singleData);

    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    try {
      if (val === "delete") {
        await service_api.serviceItem.delete(singleData.serviceItem_id, token);
      } else if (val === "enable") {
        await service_api.serviceItem.publish(singleData.serviceItem_id, token);
      } else if (val === "disable") {
        await service_api.serviceItem.archive(singleData.serviceItem_id, token);
      }

      setState((prev) => ({
        ...prev,
        isLoading: false,
        succussMessage: "success",
      }));
      setSelected("aucun");
      setActionValue("null");
      setSuccessUpdate(true);
      forceUpdateOnInsert();
    } catch (error: any) {
      setState({
        isLoading: false,
        errorMessage: "Erreur de chargement, réessayer",
      });

      if (error.message === ERROR_MESSAGE.networkError) {
        return setState({
          isLoading: false,
          errorMessage: ERROR_MESSAGE.networkError,
        });
      }
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.clear();
          return navigate("/login");
        }
      }
    }
  };
  const handleSelect = (val: string) => {
    if (val === selected) setSelected("aucun");
    else setSelected(val);
  };
  const fetchAll = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    service_api.serviceItem
      .getAll(token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  const fetchTitle = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    service_api.serviceTitle
      .getAll(token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setDataTitle(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  //activity data for title
  activityTitleData.title[0].value = dataTitle
    ? dataTitle[0]?.title
    : "Non défini";

  activityTitleData.title[1].value = dataTitle
    ? dataTitle[0]?.subtitle
    : "Non défini";

  React.useEffect(() => {
    fetchAll();
    fetchTitle();
  }, [reducerValueOnInsert]);

  return (
    <>
      <MyLoadingv3 isLoading={state.isLoading} />

      <Header />
      <MenuRoute
        menuLevelOne={"Accueil"}
        menuLevelTwo={"Pages"}
        menuLevelThree={"Service"}
      />
      <PageTitle title={"Gestion des Services"} setopen={setopen} btnTitle="" />

      <div className="container">
        <div className="left">
          {/* <div className="search">
            <Search className="ico" />
            <input type="text" />
          </div> */}

          <div className="itemss">
            <div className="search">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              {selected !== "aucun" && (
                <div className="action">
                  <span>Action: </span>
                  <select onChange={(e) => setActionValue(e.target.value)}>
                    <option value="null">----------</option>
                    <option value="delete">Supprimer</option>
                    <option value="enable">Publier</option>
                    <option value="disable">Désactiver</option>
                  </select>
                  {actionValue !== "null" && (
                    <button
                      className="text-white bg-dark"
                      onClick={() => handleValidate(actionValue)}
                    >
                      Valider
                    </button>
                  )}
                </div>
              )}
            </div>
            <ul className="responsive-tables">
              <li className="table-header bg-dark">
                <div className="col col-1">Id</div>
                <div className="col col-3">Titre </div>
                <div className="col col-3">Description</div>
                <div className="col col-3">status</div>
              </li>
              {data
                .filter(
                  (item) =>
                    item.title
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()) ||
                    item.content
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                )
                .map((value, index) => {
                  return (
                    <li className="table-row" key={index}>
                      <div className="col col-1 number" data-label="Id:">
                        <div
                          className={
                            selected === index.toString()
                              ? "case bg-dark"
                              : "case"
                          }
                          onClick={() => {
                            handleSelect(index.toString());
                            setSingleData(value);
                          }}
                        ></div>
                        {index + 1}
                      </div>
                      <div className="col col-3" data-label="Titre:">
                        {value.title}
                      </div>
                      <div className="col col-3" data-label="Contenu:">
                        {value.content?.slice(0, 20)}...
                      </div>
                      <div className="col col-3" data-label="Status:">
                        {value.serviceItem_status ===
                          STATUS_RESSOURCE_ID.PUBLISHED && (
                          <span className="status enable">
                            {STATUS_RESSOURCE.PUBLISHED}
                          </span>
                        )}
                        {value.serviceItem_status ===
                          STATUS_RESSOURCE_ID.ARCHIVED && (
                          <span className="status disable">
                            {STATUS_RESSOURCE.ARCHIVED}
                          </span>
                        )}

                        <RemoveRedEye
                          onClick={() => {
                            setSingleData(value);
                            setopenSingle(true);
                          }}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
            <div className="item">
              <div className="left_title bg-dark">
                <AppBlocking />
                <span>Entete</span>
              </div>
              {activityTitleData.title.map((value, index: number) => {
                return (
                  <div className="left_item text-dark" key={index}>
                    <span className="label">{value.label}</span>
                    <span>{value.value}</span>
                    <div className="update">
                      <Add className="text-primary" />
                      <span
                        className="text-primary"
                        onClick={() => {
                          setSingleData(value);
                          setopenUpdateTitle(true);
                        }}
                      >
                        Modifier
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Opération effectuée avec succes"}
          theme={"success"}
        />
      )}

      {openUpdateTitle && (
        <UpdateTitle
          setopen={setopenUpdateTitle}
          data={singleData}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
          service_id={dataTitle[0].service_id}
        />
      )}

      {openSingle && (
        <SingleService
          setopen={setopenSingle}
          data={singleData}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
          serviceItem_id={data[0].serviceItem_id}
        />
      )}

      {open && (
        <NewService
          setopen={setopen}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}
      <Footer />
    </>
  );
};

export default Service;
