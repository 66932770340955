import React from "react";
import { Add } from "@mui/icons-material";
import "./pageTitle.scss";

const PageTitle = ({
  title,
  btnTitle,
  setopen,
}: {
  title: string;
  btnTitle?: string;
  setopen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="page_title">
      <span>{title}</span>
      <div className="btn bg-primary" onClick={() => setopen(true)}>
        {btnTitle}
        <Add />
      </div>
    </div>
  );
};

export default PageTitle;
