export const APP_CONST = {
  APP_NAME: "LireConsulting",
  ADDRESS_NAME: "RDC, Nord-Kivu, Goma",
  PHONE_NUMBER: "+243 990 11 96 11",
  EMAIL_ADDRESS: "info@lireconsulting.org",
};
export const USER_ROLE = {
  ADMINISTRATION: "Admin",
  EDITEUR: "Editeur",
};
// export const URL_BACKEND_APP = "http://localhost/api-lire";
export const URL_BACKEND_APP = "https://api.lireconsulting.org";
// export const URL_BACKEND_APP = "www.api.lireconsulting.org";

export const ERROR_MESSAGE = {
  networkError: "Network Error",
  unknownError: "Une erreur inconnue s'est produit, réessayer",
};

export const SUCCESS_MESSAGE = {
  success: "success",
};

export const STATUS_ADMIN_ID = {
  ENABLED: 1,
  DISABLED: 0,
};
export const STATUS_ADMIN = {
  ENABLED: "Activé",
  DISABLED: "Désactivé",
};
export const STATUS_RESSOURCE_ID = {
  PUBLISHED: 1,
  ARCHIVED: 0,
};
export const STATUS_RESSOURCE = {
  PUBLISHED: "Publié",
  ARCHIVED: "Archivé",
};
