import "./home.scss";
import {
  Pages,
  AdminPanelSettings,
  Settings,
  PartyModeSharp,
} from "@mui/icons-material";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import MenuRoute from "../../components/menuroute/MenuRoute";
import { Link } from "react-router-dom";
import PageTitleWithoutBtn from "../../components/pagetitle/PageTitleWithoutBtn";
import React from "react";

const MenuList = [
  {
    title: "AUTHENTIFICATION ET AUTORISATION",
    icon: <AdminPanelSettings />,
    subTitle: [{ id: 1, name: "Administrateurs", link: "/admin" }],
  },
  {
    title: "SECTIONS",
    icon: <PartyModeSharp />,
    subTitle: [
      { id: 2, name: "Ce que nous faisons", link: "/whatwedo" },
      { id: 3, name: "Temoignage", link: "/testimonial" },
      { id: 4, name: "Paternaire", link: "/partener" },
      { id: 5, name: "Question couramment posées", link: "/faq" },
    ],
  },
  {
    title: "PAGES",
    icon: <Pages />,
    subTitle: [
      { id: 6, name: "A propos", link: "/about" },
      { id: 7, name: "Service", link: "/service" },
      { id: 8, name: "Activité", link: "/activity" },
      { id: 9, name: "Notre Equipe", link: "/team" },
    ],
  },
  {
    title: "CONFIGURATION",
    icon: <Settings />,
    subTitle: [{ id: 10, name: "Application", link: "/application" }],
  },
];

const Home = () => {
  return (
    <>
      <div className="home">
        <Header />
        <MenuRoute menuLevelOne={"Accueil"} />
        <PageTitleWithoutBtn title={"Administration du site"} />
        <div className="container">
          <div className="left">
            {MenuList.map((value, index) => (
              <React.Fragment key={index}>
                <div className="left_title bg-dark">
                  {value.icon}
                  <span>{value.title}</span>
                </div>
                {value.subTitle.map((value_2, index) => (
                  <div className="left_item text-dark" key={index}>
                    <Link to={value_2.link}>
                      <span>{value_2.id}. </span>
                      <span>{value_2.name}</span>
                    </Link>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
          {/* <div className="right">
            <div className="recent_title text-dark">
              <span>ACTIONS RECENTES</span>
            </div>
            <div className="recent_items">
              <span className="recenth1 text-dark">Mes actions</span>

              <div className="itemContainer">
                <div className="item">
                  <Edit className="ico text-primary" />
                  <div className="right_item">
                    <span>Ajout Activité</span>
                    <span>activité</span>
                  </div>
                </div>
                <div className="item">
                  <Edit className="ico text-primary" />
                  <div className="right_item">
                    <span>Ajout Activité</span>
                    <span>activité</span>
                  </div>
                </div>
                <div className="item">
                  <Add className="ico text-primary" />
                  <div className="right_item">
                    <span>Ajout Activité</span>
                    <span>activité</span>
                  </div>
                </div>
                <div className="item">
                  <Edit className="ico text-primary" />
                  <div className="right_item">
                    <span>Ajout Activité</span>
                    <span>activité</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
