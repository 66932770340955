import React, { useReducer, useState } from "react";
import "./about.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import MenuRoute from "../../components/menuroute/MenuRoute";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Add, AppBlocking } from "@mui/icons-material";
import { aboutUsData } from "../../data/data";
import UpdateTitle from "./UpdateTitle";
import UpdateContent from "./UpdateContent";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import { aboutUsType, requestType } from "../../types/globalTypes";
import SuccessAlert from "../../components/alert/AlerteBar";
import { ERROR_MESSAGE } from "../../_service/_const";
import about_api from "../../_service/api.caller/aboutCaller";
import UpdateImg from "./UpdateImg";
import AttachedFile from "../../components/attachFile/AttachedFile";
import MyLoadingv3 from "../../components/loader/Loader_v3";

const About = () => {
  const [open, setopen] = useState<boolean>(false);
  const [openUpdateTitle, setopenUpdateTitle] = useState<boolean>(false);
  const [openUpdateImg, setopenUpdateImg] = useState<boolean>(false);
  const [openUpdateContenu, setopenUpdateContenu] = useState<boolean>(false);
  const [singleData, setSingleData] = useState<any>();
  const [data, setData] = useState<aboutUsType[]>([]);
  const [openImage, setopenImage] = useState<boolean>(false);
  const [imageData, setImageData] = useState<any>();

  const [successUpdate, setSuccessUpdate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );
  const handleOpenImg = (val: any) => {
    setopenImage(true);
    setImageData(val);
  };
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const fetchAll = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    about_api
      .getAll(token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  aboutUsData.title[0].value = data[0]?.title;
  aboutUsData.title[1].value = data[0]?.subtitle;
  aboutUsData.title[2].value = data[0]?.detail;
  aboutUsData.title[3].value = data[0]?.path_img;

  React.useEffect(() => {
    fetchAll();
  }, [reducerValueOnInsert]);

  return (
    <>
      <MyLoadingv3 isLoading={state.isLoading} />

      <Header />
      <MenuRoute
        menuLevelOne={"Accueil"}
        menuLevelTwo={"Pages & Sections"}
        menuLevelThree={"Qui nous sommes"}
      />
      <PageTitle title={"Section à propos"} setopen={setopen} />
      <div className="container">
        <div className="left">
          {/* <div className="search">
            <Search className="ico" />
            <input type="text" />
          </div> */}

          <div className="items">
            <div className="item">
              <div className="left_title bg-dark">
                <AppBlocking />
                <span>Qui nous sommes</span>
              </div>
              {aboutUsData.title.map((value, index) => {
                // console.log(value);

                return (
                  <div className="left_item text-dark" key={index}>
                    <span className="label">{value.label}</span>
                    {value.label.toLowerCase() === "image" && !!value.value ? (
                      <span
                        className={
                          value.label.toLowerCase() === "image"
                            ? "text-primary"
                            : ""
                        }
                        onClick={() => handleOpenImg(value)}
                      >
                        {value.value}
                      </span>
                    ) : (
                      <span>{value.value?.slice(0, 300)}...</span>
                    )}
                    <div className="update">
                      <Add className="text-primary" />
                      <span
                        className="text-primary"
                        onClick={() => {
                          setSingleData(value);
                          //for contenu we have textArea instead of simple Input text
                          //for imag we have inputFile instead

                          value.label.toLowerCase() === "contenu"
                            ? setopenUpdateContenu(true)
                            : value.label.toLowerCase() === "image"
                            ? setopenUpdateImg(true)
                            : setopenUpdateTitle(true);
                        }}
                      >
                        Modifier
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <div className="item">
              <div className="left_title bg-dark">
                <ListAltOutlined />
                <span>Items</span>
              </div>
              {whatwedoData.items.map((value, index) => {
                return (
                  <div className="left_item text-dark" key={index}>
                    <div className="media">
                      {value.icon}
                      <span className="text-dark">{value.title}</span>
                    </div>
                    <span>{value.text.slice(0, 50)}...</span>
                    <div className="update">
                      <Add className="text-primary" />
                      <span
                        className="text-primary"
                        onClick={() => {
                          setSingleData(value);
                          setopenUpdateContenu(true);
                        }}
                      >
                        Modifier
                      </span>
                    </div>
                  </div>
                );
              })}
            </div> */}
          </div>
        </div>
      </div>

      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Opération effectuée avec succes"}
          theme={"success"}
        />
      )}

      {openUpdateTitle && (
        <UpdateTitle
          setopen={setopenUpdateTitle}
          data={singleData}
          about_id={data[0]?.about_id}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}
      {openUpdateContenu && (
        <UpdateContent
          setopen={setopenUpdateContenu}
          data={singleData}
          about_id={data[0]?.about_id}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}
      {openUpdateImg && (
        <UpdateImg
          setopen={setopenUpdateImg}
          data={singleData}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
          about_id={data[0]?.about_id}
        />
      )}

      {openImage && (
        <AttachedFile
          setOpen={setopenImage}
          data={imageData}
          title1="Qui nous sommes"
        />
      )}
      <Footer />
    </>
  );
};

export default About;
