import React from "react";
import { Add } from "@mui/icons-material";
import "./pageTitle.scss";

const PageTitleWithoutBtn = ({ title }: { title: string }) => {
  return (
    <div className="page_title">
      <span>{title}</span>
    </div>
  );
};

export default PageTitleWithoutBtn;
