import React, { useReducer, useState } from "react";
import "./faq.scss";
import Header from "../../components/Header/Header";
import MenuRoute from "../../components/menuroute/MenuRoute";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import { RemoveRedEye } from "@mui/icons-material";
import NewFaq from "../../components/faq/NewFaq";
import SingleFaq from "../../components/faq/SingleFaq";
import {
  ERROR_MESSAGE,
  STATUS_RESSOURCE,
  STATUS_RESSOURCE_ID,
} from "../../_service/_const";
import SearchBar from "../../components/searchBar/SearchBar";
import { getToken } from "../../_utils/fonctions";
import { useNavigate } from "react-router-dom";
import { faqType, requestType } from "../../types/globalTypes";
import faq_api from "../../_service/api.caller/faqCaller";
import SuccessAlert from "../../components/alert/AlerteBar";
import MyLoadingv3 from "../../components/loader/Loader_v3";

const Faq = () => {
  const [open, setopen] = useState<boolean>(false);
  const [openSingle, setopenSingle] = useState<boolean>(false);
  const [singleData, setsingleData] = useState<any>();
  const [data, setData] = useState<faqType[]>([]);
  const [actionValue, setActionValue] = useState<string>("null");
  const [selected, setSelected] = useState<string>("aucun");
  const [searchValue, setSearchValue] = React.useState<string>("");

  const [successUpdate, setSuccessUpdate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const handleValidate = async (val: string) => {
    // console.log(val);
    // console.log(selected);
    // console.log(singleData);

    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    try {
      if (val === "delete") {
        await faq_api.delete(singleData.faq_id, token);
      } else if (val === "enable") {
        await faq_api.publish(singleData.faq_id, token);
      } else if (val === "disable") {
        await faq_api.archive(singleData.faq_id, token);
      }

      setState((prev) => ({
        ...prev,
        isLoading: false,
        succussMessage: "success",
      }));
      setSelected("aucun");
      setActionValue("null");
      setSuccessUpdate(true);
      forceUpdateOnInsert();
    } catch (error: any) {
      setState({
        isLoading: false,
        errorMessage: "Erreur de chargement, réessayer",
      });

      if (error.message === ERROR_MESSAGE.networkError) {
        return setState({
          isLoading: false,
          errorMessage: ERROR_MESSAGE.networkError,
        });
      }
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.clear();
          return navigate("/login");
        }
      }
    }
  };
  const handleSelect = (val: string) => {
    if (val === selected) setSelected("aucun");
    else setSelected(val);
  };
  const fetchAll = () => {
    let token = getToken();
    if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    faq_api
      .getAll(token)
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      });
  };

  React.useEffect(() => {
    fetchAll();
  }, [reducerValueOnInsert]);

  return (
    <>
      <MyLoadingv3 isLoading={state.isLoading} />

      <Header />
      <MenuRoute
        menuLevelOne={"Accueil"}
        menuLevelTwo={"Pages & Sections"}
        menuLevelThree={"Faq"}
      />
      <PageTitle
        title={"Gestion des questions couramment posées"}
        btnTitle=""
        setopen={setopen}
      />
      <div className="container">
        <div className="left">
          <div className="search">
            <SearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            {selected !== "aucun" && (
              <div className="action">
                <span>Action: </span>
                <select onChange={(e) => setActionValue(e.target.value)}>
                  <option value="null">----------</option>
                  <option value="delete">Supprimer</option>
                  <option value="enable">Publier</option>
                  <option value="disable">Archiver</option>
                </select>
                {actionValue !== "null" && (
                  <button
                    className="text-white bg-dark"
                    onClick={() => handleValidate(actionValue)}
                  >
                    Valider
                  </button>
                )}
              </div>
            )}
          </div>

          <ul className="responsive-table">
            <li className="table-header bg-dark">
              <div className="col col-1">Id</div>
              <div className="col col-3">Question </div>
              <div className="col col-4">Réponse</div>
              <div className="col col-3">Status</div>
            </li>
            {data
              .filter(
                (item) =>
                  item.question
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  item.answer.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((value: faqType, index: number) => {
                return (
                  <li className="table-row" key={index}>
                    <div className="col col-1 number" data-label="Id:">
                      <div
                        className={
                          selected === index.toString()
                            ? "case bg-dark"
                            : "case"
                        }
                        onClick={() => {
                          handleSelect(index.toString());
                          setsingleData(value);
                        }}
                      ></div>
                      {index + 1}
                    </div>
                    <div className="col col-3" data-label="Question:">
                      {value.question}
                    </div>
                    <div className="col col-4" data-label="Réponse:">
                      {value.answer.slice(0, 15)}
                    </div>

                    <div className="col col-3" data-label="Status:">
                      {value.faq_status === STATUS_RESSOURCE_ID.PUBLISHED && (
                        <span className="status enable">
                          {STATUS_RESSOURCE.PUBLISHED}
                        </span>
                      )}
                      {value.faq_status === STATUS_RESSOURCE_ID.ARCHIVED && (
                        <span className="status disable">
                          {STATUS_RESSOURCE.ARCHIVED}
                        </span>
                      )}

                      <RemoveRedEye
                        onClick={() => {
                          setsingleData(value);
                          setopenSingle(true);
                        }}
                      />
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        {/* <div className="right">
          <div className="recent_title text-dark">
            <span>FILTRE</span>
          </div>
          <div className="recent_items">
            <span className="recenth1 text-dark">Tous</span>

            <div className="itemContainer">
              <div className="item">
                <Edit className="ico text-primary" />
                <div className="right_item">
                  <span>Ajout Activité</span>
                  <span>activité</span>
                </div>
              </div>
              <div className="item">
                <Edit className="ico text-primary" />
                <div className="right_item">
                  <span>Ajout Activité</span>
                  <span>activité</span>
                </div>
              </div>
              <div className="item">
                <Add className="ico text-primary" />
                <div className="right_item">
                  <span>Ajout Activité</span>
                  <span>activité</span>
                </div>
              </div>
              <div className="item">
                <Edit className="ico text-primary" />
                <div className="right_item">
                  <span>Ajout Activité</span>
                  <span>activité</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {open && (
        <NewFaq
          setopen={setopen}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}

      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Opération effectuée avec succes"}
          theme={"success"}
        />
      )}

      {openSingle && (
        <SingleFaq
          setopen={setopenSingle}
          data={singleData}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessUpdate={setSuccessUpdate}
        />
      )}
      <Footer />
    </>
  );
};

export default Faq;
