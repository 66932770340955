import React from "react";
import "./menuRoute.scss";
import { ArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";

interface Props {
  menuLevelOne?: String;
  menuLevelTwo?: String;
  menuLevelThree?: String;
}

const MenuRoute = (props: Props) => {
  return (
    <div className="links">
      <Link to={"/"}>
        <span>{props.menuLevelOne}</span>
        {!!props.menuLevelOne && <ArrowRight />}
      </Link>
      <span className="menu">{props.menuLevelTwo}</span>
      {!!props.menuLevelTwo && <ArrowRight />}
      <span className="subMenu">{props.menuLevelThree}</span>
    </div>
  );
};

export default MenuRoute;
