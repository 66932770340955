import "moment/locale/fr";
import "./styles/global.scss";
import {} from "@mui/material/Icon";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import WhatWeDo from "./pages/whatwedo/WhatWeDo";
import About from "./pages/about/About";
// import Service from "./pages/service/Service";

import Team from "./pages/team/Team";
import Request from "./pages/request/Request";
import NotFound from "./pages/404/NotFound";
import Activity from "./pages/activity/Activity";
import Application from "./pages/application/Application";
import Partener from "./pages/partener/Partener";
import Faq from "./pages/faq/Faq";
import Admin from "./pages/user/Admin";
import Testimonial from "./pages/Testimonial/Testimonial";
import Login from "./pages/login/Login";
import Service from "./pages/service/Service";
import { AuthContext } from "./context/AuthContext";
import { useContext } from "react";

type Props = {
  children: JSX.Element;
};

function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }: Props) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="/whatwedo"
          element={
            <RequireAuth>
              <WhatWeDo />
            </RequireAuth>
          }
        />
        <Route
          path="/about"
          element={
            <RequireAuth>
              <About />
            </RequireAuth>
          }
        />
        <Route
          path="/service"
          element={
            <RequireAuth>
              <Service />
            </RequireAuth>
          }
        />
        <Route
          path="/activity"
          element={
            <RequireAuth>
              <Activity />
            </RequireAuth>
          }
        />
        <Route
          path="/admin"
          element={
            <RequireAuth>
              <Admin />
            </RequireAuth>
          }
        />
        <Route
          path="/application"
          element={
            <RequireAuth>
              <Application />
            </RequireAuth>
          }
        />
        <Route
          path="/team"
          element={
            <RequireAuth>
              <Team />
            </RequireAuth>
          }
        />
        <Route
          path="/testimonial"
          element={
            <RequireAuth>
              <Testimonial />
            </RequireAuth>
          }
        />
        <Route
          path="/request"
          element={
            <RequireAuth>
              <Request />
            </RequireAuth>
          }
        />
        <Route
          path="/partener"
          element={
            <RequireAuth>
              <Partener />
            </RequireAuth>
          }
        />
        <Route
          path="/faq"
          element={
            <RequireAuth>
              <Faq />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
